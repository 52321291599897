import { styled } from '@frontend/theme';
import { Badge } from '@mui/material';

export const BellBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    height: '7px',
    minWidth: '7px',
    top: '20%',
    right: '30%',
    border: '1px solid #fff',
  },
}));
