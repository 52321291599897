import {
  ButtonsWrapper,
  CaptionBox,
  DualBox,
  FieldsWrapper,
  FormBox,
  FormContainer,
} from './ProfileForm.css';
import { Typography } from '@mui/material';
import { Translate } from '@frontend/translation';
import { IProfileForm, ProfileFormRef } from './ProfileForm.types';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { matchIsValidTel } from 'mui-tel-input';
import { ControllerRenderProps, useController, useForm } from 'react-hook-form';
import { IProfiledataKeys, IProfileFormSchema } from '@frontend/api';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle } from 'react';
import { useFormTracking } from '../utils/useFormTracking';
import { Button, CheckBox, TextField } from '@frontend/ui-elements';
import PhoneField from '@customer-platform-shared/components/ui-csr/PhoneField/PhoneField';

const ProfileForm = forwardRef<ProfileFormRef, IProfileForm>(function (
  {
    userProfile,
    errors,
    isSuccess,
    isPending,
    isReset,
    onSubmit,
    onCancel,
    hasUnsavedChanges,
  }: IProfileForm,
  ref,
) {
  const intl = useIntl();
  const requiredField = intl.formatMessage({
    id: 'customer-platform.shared.components.forms.errors.required',
  });
  const formId = 'profile-form.form';

  const profileFormSchema = yup.object().shape({
    user: yup.object().shape({
      firstName: yup.string().required(requiredField),
      lastName: yup.string().required(requiredField),
      phoneNumber: yup
        .string()
        .test(
          'phoneNumberValidation',
          intl.formatMessage({
            id: 'customer-platform.shared.components.forms.errors.phone_number.invalid',
          }),
          (phoneNumber = '') => {
            if (!phoneNumber) {
              return true;
            }
            return matchIsValidTel(phoneNumber);
          },
        )
        .required(requiredField),
      whatsappPhoneNumber: yup
        .string()
        .test(
          'phoneNumberValidation',
          intl.formatMessage({
            id: 'customer-platform.shared.components.forms.errors.phone_number.invalid',
          }),
          (phoneNumber = '') => {
            if (!phoneNumber) {
              return true;
            }
            return matchIsValidTel(phoneNumber);
          },
        )
        .required(requiredField),
      whatsappConsent: yup.boolean().required(),
    }),
    company: yup.object().shape({
      address: yup.string().optional(),
      postalCode: yup
        .string()
        .nullable()
        .optional()
        .transform(value => (value === '' ? null : value)),
    }),
  });

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors: formErrors, isDirty: isFormHasChanges },
  } = useForm<IProfileFormSchema>({
    defaultValues: {
      user: {
        firstName: userProfile?.user?.firstName || '',
        lastName: userProfile?.user?.lastName || '',
        phoneNumber: userProfile?.user?.phoneNumber || '',
        whatsappPhoneNumber: userProfile?.user?.whatsappPhoneNumber || '',
        whatsappConsent: userProfile?.user?.whatsappConsent || false,
      },
      company: {
        address: userProfile?.company?.address || '',
        postalCode: userProfile?.company?.postalCode ?? '',
      },
    },
    resolver: yupResolver<IProfileFormSchema>(profileFormSchema),
  });

  const handleChange = (field: ControllerRenderProps) => (value: string) => {
    field.onChange(value);
  };

  const handleEventChange =
    (field: ControllerRenderProps) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      field.onChange(event.target.value);
    };

  const { field: firstNameField } = useController({
    name: 'user.firstName',
    control,
  });

  const { field: lastNameField } = useController({
    name: 'user.lastName',
    control,
  });

  const { field: phoneNumberField } = useController({
    name: 'user.phoneNumber',
    control,
  });

  const { field: whatsappPhoneNumberField } = useController({
    name: 'user.whatsappPhoneNumber',
    control,
  });

  const { field: whatsappConsentField } = useController({
    name: 'user.whatsappConsent',
    control,
  });

  const { field: addressField } = useController({
    name: 'company.address',
    control,
  });

  const { field: postalCodeField } = useController({
    name: 'company.postalCode',
    control,
  });

  const handleCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    whatsappConsentField.onChange(event.target.checked);
  };

  useEffect(() => {
    if (errors !== undefined) {
      for (const [errorType, errorObject] of Object.entries(errors)) {
        for (const [error, errorMessage] of Object.entries(errorObject)) {
          setError(`${errorType}.${error}` as IProfiledataKeys, {
            type: 'manual',
            message: errorMessage ? (errorMessage as unknown as string) : '',
          });
        }
      }
    }
  }, [errors, setError]);

  useEffect(() => {
    if (isReset) {
      reset();
    }
  }, [isReset]);

  useEffect(() => {
    hasUnsavedChanges(isFormHasChanges);
  }, [isFormHasChanges]);

  useImperativeHandle(ref, () => ({
    invokeSubmit() {
      handleSubmit(onSubmit)();
    },
  }));

  useFormTracking({ formId, formErrors, isSuccess });

  return (
    <FormContainer>
      <CaptionBox>
        <Typography variant="p1">
          <Translate id="customer-platform.shared.user-profile.edit-profile.form.heading" />
        </Typography>
        <Typography component="p" variant="caption2">
          <Translate id="customer-platform.shared.user-profile.edit-profile.form.caption" />
        </Typography>
      </CaptionBox>

      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        id={formId}
        name={formId}
      >
        <FormBox>
          <FieldsWrapper>
            <Typography variant="h6">
              <Translate id="customer-platform.shared.user-profile.edit-profile.form.profile.title" />
            </Typography>
            <DualBox>
              <TextField
                id="first-name"
                label={
                  <Translate id="customer-platform.shared.user-profile.edit-profile.form.textfield.first-name" />
                }
                value={firstNameField.value}
                onChange={handleEventChange(
                  firstNameField as unknown as ControllerRenderProps,
                )}
                error={!!formErrors?.user?.firstName}
                helperText={formErrors?.user?.firstName?.message}
                formId={formId}
                required
              />
              <TextField
                id="last-name"
                label={
                  <Translate id="customer-platform.shared.user-profile.edit-profile.form.textfield.last-name" />
                }
                value={lastNameField.value}
                onChange={handleEventChange(
                  lastNameField as unknown as ControllerRenderProps,
                )}
                error={!!formErrors?.user?.lastName}
                helperText={formErrors?.user?.lastName?.message}
                formId={formId}
                required
              />
            </DualBox>
            <PhoneField
              id="phone-number"
              label={
                <Translate id="customer-platform.shared.user-profile.edit-profile.form.textfield.phone-number" />
              }
              defaultCountry="DE"
              value={phoneNumberField.value}
              onChange={handleChange(
                phoneNumberField as unknown as ControllerRenderProps,
              )}
              error={!!formErrors?.user?.phoneNumber}
              helperText={formErrors?.user?.phoneNumber?.message}
              formId={formId}
              required
            />
            <PhoneField
              id="whatsapp-number"
              label={
                <Translate id="customer-platform.shared.user-profile.edit-profile.form.textfield.whatsapp-number" />
              }
              defaultCountry="DE"
              value={whatsappPhoneNumberField.value}
              onChange={handleChange(
                whatsappPhoneNumberField as unknown as ControllerRenderProps,
              )}
              error={!!formErrors?.user?.whatsappPhoneNumber}
              helperText={formErrors?.user?.whatsappPhoneNumber?.message}
              formId={formId}
              required
            />
            <CheckBox
              id="whatsapp-consent"
              label={
                <Translate id="customer-platform.shared.user-profile.edit-profile.form.checkbox.whatsapp-consent" />
              }
              value={whatsappConsentField.value as boolean}
              onChange={handleCheckBoxChange}
              error={!!formErrors?.user?.whatsappConsent}
              helperText={formErrors?.user?.whatsappConsent?.message}
              formId={formId}
              size="small"
            />
            <Typography variant="h6">
              <Translate id="customer-platform.shared.user-profile.edit-profile.form.company.title" />
            </Typography>
            <TextField
              id="street"
              label={
                <Translate id="customer-platform.shared.user-profile.edit-profile.form.textfield.address" />
              }
              value={addressField.value}
              onChange={handleEventChange(
                addressField as unknown as ControllerRenderProps,
              )}
              error={!!formErrors?.company?.address}
              helperText={formErrors?.company?.address?.message}
              formId={formId}
            />
            <TextField
              id="postal-code"
              label={
                <Translate id="customer-platform.shared.user-profile.edit-profile.form.textfield.postal-code" />
              }
              value={postalCodeField.value}
              onChange={handleEventChange(
                postalCodeField as unknown as ControllerRenderProps,
              )}
              error={!!formErrors?.company?.postalCode}
              helperText={formErrors?.company?.postalCode?.message}
              formId={formId}
            />
          </FieldsWrapper>
          <ButtonsWrapper>
            <Button
              buttonType="secondary"
              id="profile-form.button.cancel"
              size="large"
              type="button"
              onClick={onCancel}
            >
              <Translate id="customer-platform.shared.user-profile.edit-profile.form.button.cancel" />
            </Button>
            <Button
              buttonType="primary"
              id="profile-form.button.submit"
              size="large"
              type="submit"
              isSubmitting={isPending}
            >
              <Translate id="customer-platform.shared.user-profile.edit-profile.form.button.submit" />
            </Button>
          </ButtonsWrapper>
        </FormBox>
      </form>
    </FormContainer>
  );
});

export default ProfileForm;
