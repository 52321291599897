export const SEARCHABLE_ITEM_TYPE = {
  REQUEST: 'request',
  LISTING: 'listing',
  REQUEST_ORDER: 'requestOrder',
  LISTING_ORDER: 'listingOrder',
} as const;

export type SearchableItemType =
  (typeof SEARCHABLE_ITEM_TYPE)[keyof typeof SEARCHABLE_ITEM_TYPE];

export interface ISearchableItem {
  itemType: SearchableItemType;
  label: string;
  id: string;
  parentId?: string;
}

export interface SearchBarProps {
  onSelect: (
    searchableItem: ISearchableItem | null,
    searchedTerm: string,
  ) => void;
  searchableItems: ISearchableItem[];
  placeholder: string;
}
