import { styled } from '@frontend/theme';

export const ListItem = styled('li')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '4px',
}));

export const IconWrapper = styled('div')(() => ({
  minWidth: '16px',
  display: 'flex',
  alignItems: 'center',
  marginTop: '4px',
}));
