import { Typography } from '@mui/material';
import { NotificationsEmpty } from './UserNotificationsEmpty.css';
import { Translate } from '@frontend/translation';

export default function UserNotificationsEmpty() {
  return (
    <>
      <NotificationsEmpty>
        <Typography component="p" variant="sh1">
          <Translate id="customer-platform.header.user.notifications.empty.title" />
        </Typography>
        <Typography component="p" variant="sh3">
          <Translate id="customer-platform.header.user.notifications.empty.description" />
        </Typography>
      </NotificationsEmpty>
    </>
  );
}
