import { Typography, useTheme } from '@mui/material';
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleFill,
} from '@remixicon/react';
import { useIntl } from 'react-intl';
import { IOrderPhotosCheckListItem } from './OrderPhotoCheckListItem.types';
import { IconWrapper, ListItem } from './OrderPhotoCheckListItem.css';

export default function OrderPhotosCheckListItem({
  translationId,
  isUploaded,
  tag,
}: IOrderPhotosCheckListItem) {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <ListItem>
      <IconWrapper>
        {isUploaded ? (
          <RiCheckboxCircleFill
            data-testid={`uploaded-photo-icon.${tag}`}
            size={16}
            color={theme.palette.primary[500]}
          />
        ) : (
          <RiCheckboxBlankCircleLine
            data-testid={`missing-photo-icon.${tag}`}
            size={16}
          />
        )}
      </IconWrapper>
      <Typography variant="p3">
        {intl.formatMessage({ id: translationId })}
      </Typography>
    </ListItem>
  );
}
