import {
  SearchBar,
  SEARCHABLE_ITEM_TYPE,
  ISearchableItem,
} from '@frontend/ui-elements';
import {
  ISearchIndexItem,
  OPERATION_MODE_TYPE_ENUM,
  useGetSearchIndex,
  USER_ROLE_TYPE_ENUM,
} from '@frontend/api';
import { DashboardSearchProps } from './DashboardSearch.types';
import { DashboardSearchWrapper } from './DashboardSearch.css';
import {
  generatePath,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { createQueryParams, PLATFORM_ROUTES } from '@frontend/routing';
import { useMediaQuery } from '@mui/material';
import { theme } from '@frontend/theme';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { trackPerformSearchEvent } from '@frontend/tracking';

const DashboardSearch = ({ userRole }: DashboardSearchProps) => {
  const { isSuccess: searchIndexSuccess, data: searchIndex } =
    useGetSearchIndex(
      userRole === USER_ROLE_TYPE_ENUM.BUYER
        ? OPERATION_MODE_TYPE_ENUM.BUYER
        : OPERATION_MODE_TYPE_ENUM.SELLER,
    );

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const [searchableItems, setSearchableItems] = useState<ISearchableItem[]>([]);
  const intl = useIntl();
  const isRequestDetail = useMatch(`${PLATFORM_ROUTES.requestDetails}/*`);
  const isListingDetail = useMatch(`${PLATFORM_ROUTES.listingDetails}/*`);

  useEffect(() => {
    if (
      searchIndexSuccess &&
      searchIndex &&
      searchIndex.data &&
      searchIndex.data.requests
    ) {
      setSearchableItems(
        processSearchIndex(
          userRole === USER_ROLE_TYPE_ENUM.BUYER
            ? searchIndex.data.requests
            : searchIndex.data.listings,
        ),
      );
    } else {
      setSearchableItems([]);
    }
  }, [searchIndexSuccess]);

  const resetSearch = () => {
    if (searchParams.has('searchItem') || searchParams.has('searchTerm')) {
      searchParams.delete('searchItem');
      searchParams.delete('searchTerm');
      setSearchParams(searchParams);
    }
    return;
  };

  const goToItemDetails = (item: ISearchableItem, search: string) => {
    let destinationPage = '';
    switch (item.itemType) {
      case SEARCHABLE_ITEM_TYPE.REQUEST:
        destinationPage = generatePath(PLATFORM_ROUTES.requestDetails, {
          uuid: item.id,
        });
        break;
      case SEARCHABLE_ITEM_TYPE.LISTING:
        destinationPage = generatePath(PLATFORM_ROUTES.listingDetails, {
          uuid: item.id,
        });
        break;
      case SEARCHABLE_ITEM_TYPE.REQUEST_ORDER:
        destinationPage = generatePath(PLATFORM_ROUTES.orderDetails, {
          uuid: item.parentId ?? '',
          orderId: item.id,
        });
        break;
      case SEARCHABLE_ITEM_TYPE.LISTING_ORDER:
        destinationPage = generatePath(PLATFORM_ROUTES.listingOrderDetails, {
          uuid: item.parentId ?? '',
          orderId: item.id,
        });
        break;
    }
    navigate(`${destinationPage}?${search}`, { replace: true });
  };

  const goToFilteredItemsList = (uuid: string, search: string) => {
    const destinationPage = generatePath(
      userRole === USER_ROLE_TYPE_ENUM.BUYER
        ? PLATFORM_ROUTES.buyerDashboard
        : PLATFORM_ROUTES.sellerDashboard,
      { uuid: uuid ?? '' },
    );
    navigate(`${destinationPage}?${search}`, { replace: true });
  };

  const itemIsOrder = (searchableItem: ISearchableItem): boolean => {
    return (
      searchableItem.itemType === SEARCHABLE_ITEM_TYPE.LISTING_ORDER ||
      searchableItem.itemType === SEARCHABLE_ITEM_TYPE.REQUEST_ORDER
    );
  };

  const onEntrySelected = (
    searchableItem: ISearchableItem | null,
    searchedTerm: string,
  ) => {
    trackPerformSearchEvent({ searchedTerm });
    if (!searchableItem && !searchedTerm) {
      resetSearch();
      return;
    }
    const search = createQueryParams({
      searchTerm: searchedTerm,
      searchItem: searchableItem
        ? searchableItem.parentId
          ? searchableItem.parentId
          : searchableItem.id
        : '',
    });
    setSearchParams(search);
    if (!searchableItem) {
      goToFilteredItemsList('', search);
    } else {
      if (isDesktop || itemIsOrder(searchableItem)) {
        goToItemDetails(searchableItem, search);
      } else {
        if (isRequestDetail || isListingDetail) {
          goToFilteredItemsList(searchableItem.id, search);
        }
      }
    }
  };

  const processSearchIndex = (searchIndex: ISearchIndexItem[]) => {
    const searchablesItems: ISearchableItem[] = [];
    searchIndex.forEach((value: ISearchIndexItem) => {
      searchablesItems.push({
        itemType:
          userRole === USER_ROLE_TYPE_ENUM.BUYER
            ? SEARCHABLE_ITEM_TYPE.REQUEST
            : SEARCHABLE_ITEM_TYPE.LISTING,
        label: value.searchableKeys.pipedriveId.includes('-')
          ? value.searchableKeys.publicId
          : value.searchableKeys.pipedriveId,
        id: value.uuid,
      });
      value.orders?.forEach((orderValue: ISearchIndexItem) => {
        searchablesItems.push({
          itemType:
            userRole === USER_ROLE_TYPE_ENUM.BUYER
              ? SEARCHABLE_ITEM_TYPE.REQUEST_ORDER
              : SEARCHABLE_ITEM_TYPE.LISTING_ORDER,
          label: orderValue.searchableKeys.pipedriveId.includes('-')
            ? orderValue.searchableKeys.publicId
            : orderValue.searchableKeys.pipedriveId,
          id: orderValue.uuid,
          parentId: value.uuid,
        });
      });
    });
    return searchablesItems;
  };

  return (
    <DashboardSearchWrapper>
      <SearchBar
        onSelect={onEntrySelected}
        searchableItems={searchableItems}
        placeholder={intl.formatMessage({
          id:
            userRole === USER_ROLE_TYPE_ENUM.BUYER
              ? 'customer-platform.search.placeholder.buyer'
              : 'customer-platform.search.placeholder.seller',
        })}
      />
    </DashboardSearchWrapper>
  );
};
export default DashboardSearch;
