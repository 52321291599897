import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const HeaderDrawerWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '534px',
  [theme.breakpoints.down('desktop')]: {
    width: '100%',
  },
}));

export const UserProfileWrapper = styled(MUIBox)(({ theme }) => ({
  [theme.breakpoints.down('tabletL')]: {
    paddingBottom: '100px',
  },
}));

export const EditButtonWrapper = styled(MUIBox)({
  marginTop: '24px',
});

export const LoaderWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
}));
