import EVENTS from './tracking.const';

export type TDevice = 'desktop' | 'tablet' | 'mobile';

export const PAGE_PATH_PARAMS = {
  REQUESTS: 'requests',
  LISTINGS: 'listings',
  ORDERS: 'orders',
} as const;
export type TPagePathParam =
  (typeof PAGE_PATH_PARAMS)[keyof typeof PAGE_PATH_PARAMS];

export type FormFieldAction =
  | 'focus'
  | 'unfocus'
  | 'update'
  | 'clear'
  | 'click'
  | 'drop';

type EventType = (typeof EVENTS)[keyof typeof EVENTS];

export type TCustomDataLayer = {
  event: EventType;
  pagePath?: string;
  elementId?: string;
  elementText?: string;
  elementNavigatesTo?: string;
  scrolledPercentage?: number;
  scrollDirection?: 'down' | 'up';
  formId?: string;
  formName?: string;
  formStatus?: 'success' | 'error';
  formField?: string;
  formFieldAction?: FormFieldAction;
  formFieldOldValue?: string | null;
  formFieldNewValue?: string | null;
};

export type ClickEventTrackingData = {
  elementId?: string;
  elementText?: string;
  href?: string;
};
export type ScrollEventTrackingData = {
  scrolledPercentage?: number;
  scrollDirection?: 'down' | 'up';
};

export type FormSubmitEventTrackingData = {
  formId: string;
  formName: string;
  formStatus: 'success' | 'error';
};
export type ModalViewEventTrackingData = {
  elementId: string;
};

export type PerformSearchEventTrackingData = {
  searchedTerm: string;
};

export type DrawerViewEventTrackingData = {
  elementId: string;
};

export type FormFieldEventTrackingData = {
  formId: string;
  formField: string;
  formFieldAction?: FormFieldAction;
  formFieldOldValue?: string | null;
  formFieldNewValue?: string | null;
};
