import { styled } from '@frontend/theme';
import {
  Snackbar as MUISnackbar,
  SnackbarContent as MUISnackbarContent,
} from '@mui/material';
import { SnackbarProgressBarProps } from './ToastSnackbar.types';
import { ProgressBar as BaseProgressBar } from '../../ProgressBar/ProgressBar';

export const Snackbar = styled(MUISnackbar)(({ theme }) => ({
  position: 'fixed',
  right: 'auto',
  bottom: '60px',
  left: 'auto',
  [theme.breakpoints.down('tabletL')]: {
    width: 'calc(100% - 40px)',
  },
}));

export const SnackbarContent = styled(MUISnackbarContent)(({ theme }) => ({
  flexDirection: 'column',
  margin: 0,
  padding: 0,

  // Alert Container
  '& > .MuiSnackbarContent-message': {
    padding: 0,
    [theme.breakpoints.down('desktop')]: {
      width: '100%',
    },
  },

  // ProgressBar Container
  '& > .MuiSnackbarContent-action': {
    margin: 0,
    padding: 0,
    width: '100%',
  },
}));

export const SnackbarProgressBar = styled(BaseProgressBar, {
  shouldForwardProp: prop => prop !== 'alertType',
})<SnackbarProgressBarProps>(({ theme, alertType }) => {
  const progressBarBackgroundColorMap = {
    error: theme?.palette.error[50],
    info: theme?.palette.tertiary[500],
    success: theme?.palette.secondary[50],
    warning: theme?.palette.warning[100],
  };

  const progressBarColorMap = {
    error: theme?.palette.error[500],
    info: theme?.palette.primary[900],
    success: theme?.palette.secondary[500],
    warning: theme?.palette.warning[800],
  };

  return {
    backgroundColor:
      progressBarBackgroundColorMap[alertType] || theme?.palette.tertiary[500],
    '& > .MuiLinearProgress-bar': {
      backgroundColor:
        progressBarColorMap[alertType] || theme?.palette.secondary[500],
    },
  };
});
