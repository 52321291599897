import { styled } from '@frontend/theme';
import { Alert as MUIAlert } from '@mui/material';

export const MUIAlertSt = styled(MUIAlert)(({ theme }) => ({
  gap: '12px',
  borderRadius: 0,
  marginTop: 0,
  marginBottom: 0,
  padding: '8px 16px',
  width: '100%',

  '&.MuiAlert-standardError': {
    backgroundColor: theme.palette.error[50],
  },
  '&.MuiAlert-standardInfo': {
    backgroundColor: theme.palette.tertiary[500],
  },
  '&.MuiAlert-standardSuccess': {
    backgroundColor: theme.palette.secondary[50],
  },
  '&.MuiAlert-standardWarning': {
    backgroundColor: theme.palette.warning[100],
  },

  '& > .MuiAlert-icon': {
    flexWrap: 'wrap',
    alignItems: 'center',
    color: theme.palette.neutral[900],
    marginRight: 0,
    padding: 0,
    opacity: 1,
  },
  '& > .MuiAlert-message': {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flex: '1 0 0',
    gap: '4px',
    color: theme.palette.primary[900],
  },
  '& > .MuiAlert-action': {
    padding: '0',
    alignItems: 'center',
    '& > .MuiIconButton-root': {
      padding: '4px',
      color: theme.palette.primary[900],
    },
  },
  '& > .MuiAlert-message:has(.MuiAlertTitle-root + p)': {
    padding: 0,
  },
  '& > .MuiAlert-icon:has(+ .MuiAlert-message p)': {
    alignItems: 'flex-start',
  },
}));
