import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { Button } from '@frontend/ui-elements';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { MainWrapper } from './OrderPhotos.css';
import { OrderPhotosProps } from './OrderPhotos.types';
import OrderPhotosDrawer from './OrderPhotosDrawer/OrderPhotosDrawer';
import SuccessToast from '@customer-platform-shared/components/Toasts/SuccessToast/SuccessToast';
import OrderPhotosChecklist from './OrderPhotosCheckList/OrderPhotosCheckList';

const OrderPhotos = ({
  orderId,
  alreadyUploadedFiles,
  onOrderUpdate,
  photosSectionRef,
  readOnly,
}: OrderPhotosProps) => {
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const headerPortal = document.getElementById('header-toolbar');

  const handleOpenPhotoManager = () => {
    const photoManagerPath = generatePath(PLATFORM_ROUTES.listingOrderPhotos, {
      uuid: uuid as string,
      orderId,
    });
    navigate(photoManagerPath, { replace: true });
  };

  return (
    <MainWrapper ref={photosSectionRef}>
      <Typography variant="h5">
        <Translate id="customer-platform.listing-details.order-photos.title" />
      </Typography>
      <OrderPhotosChecklist alreadyUploadedFiles={alreadyUploadedFiles} />
      <OrderPhotosDrawer
        orderId={orderId}
        alreadyUploadedFiles={alreadyUploadedFiles}
        readOnly={readOnly}
        onSuccess={() => {
          setIsSuccessToastOpen(true);
          onOrderUpdate && onOrderUpdate();
        }}
      />
      {headerPortal && (
        <>
          {createPortal(
            <SuccessToast
              isToastOpen={isSuccessToastOpen}
              closeToast={() => setIsSuccessToastOpen(false)}
              titleId="customer-platform.listing-details.order-photos.file-manager.success-toast.title"
              messageId="customer-platform.listing-details.order-photos.file-manager.success-toast.message"
            />,
            headerPortal,
          )}
        </>
      )}

      <Button
        buttonType="primary"
        id={'order-photos.button.open-file-manager'}
        size="large"
        data-testid="order-photos-cta"
        onClick={handleOpenPhotoManager}
      >
        {readOnly ? (
          <Translate id="customer-platform.listing-details.order-photos.cta.open-drawer-read-only" />
        ) : (
          <Translate id="customer-platform.listing-details.order-photos.cta.open-drawer" />
        )}
      </Button>
    </MainWrapper>
  );
};

export default OrderPhotos;
