import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';

import {
  IUpdateUserNotificationsStatusParams,
  IUpdateUserNotificationsStatusResponseFailure,
  TUpdateUserNotificationsStatusResponse,
} from './updateUserNotificationsStatus.types';
import { IOperationModeType } from '../getCurrentUser/getCurrentUser.types';
import { getQueryKey } from '../getUserNotificationsList/getUserNotificationsList.const';

const submitUpdateUserNotificationsStatus = (
  apiUrl: string,
  { uuid, isSeen, operationMode }: IUpdateUserNotificationsStatusParams,
) => {
  return axiosClient.patch(
    `${apiUrl}/notifications/update/?${uuid ? 'uuid=' + uuid + '&' : ''}current_operations_mode=${operationMode}`,
    {
      isSeen,
    },
  );
};

const useUpdateUserNotificationsStatus = (
  operationMode: IOperationModeType,
) => {
  const { apiUrl } = useContext(ApiContext);
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<TUpdateUserNotificationsStatusResponse>,
    AxiosError<IUpdateUserNotificationsStatusResponseFailure>,
    IUpdateUserNotificationsStatusParams
  >({
    mutationFn: IUpdateUserNotificationsStatusParams =>
      submitUpdateUserNotificationsStatus(
        apiUrl,
        IUpdateUserNotificationsStatusParams,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(operationMode),
      });
    },
  });
};

export default useUpdateUserNotificationsStatus;
