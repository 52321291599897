import { BannerWrapper } from './NotificationBanner.css';
import EmailVerificationBanner from '../EmailVerificationBanner/EmailVerificationBanner';
import CompanyStatusBanner from '../DashboardHeader/CompanyStatusBanner/CompanyStatusBanner';

export default function NotificationBanners() {
  return (
    <BannerWrapper>
      <EmailVerificationBanner />
      <CompanyStatusBanner />
    </BannerWrapper>
  );
}
