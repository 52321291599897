export const EVENTS = {
  CLICK: 'click',
  FORM_SUBMIT: 'form_submit',
  PAGE_VIEW: 'page_view',
  SCROLL: 'scroll',
  SESSION_START: 'session_start',
  FORM_FIELD: 'form_field',
  MODAL_VIEW: 'modal_view',
  PERFORM_SEARCH: 'perform_search',
  DRAWER_VIEW: 'drawer_view',
} as const;

export default EVENTS;
