import { useIntl } from 'react-intl';
import UserNotificationsBell from '../UserNotificationsBell/UserNotificationsBell';
import IUserNotificationsMobile from './UserNotificationsMobile.types';
import { NotificationsDrawerWrapper } from './UserNotificationsMobile.css';
import UserNotificationsEmpty from '../UserNotificationsEmpty/UserNotificationsEmpty';
import { DrawerWithHeader } from '@frontend/ui-elements';
import { useNotifications } from '../useNotifications/useNotifications';
import UserNotificationsList from '../UserNotificationsList/UserNotificationsList';
import { NOTIFICATIONS_POLLING_INTERVAL } from '@frontend/api';

export default function UserNotificationsMobile({
  closeElement,
  isNotificationsDrawerOpen,
  openNotificationsDrawer,
}: IUserNotificationsMobile) {
  const intl = useIntl();
  const { userNotificationsList, newNotificationsCount } = useNotifications(
    NOTIFICATIONS_POLLING_INTERVAL,
  );

  const handleOpenNotifications = () => {
    openNotificationsDrawer();
  };

  return (
    <>
      <UserNotificationsBell
        isNewNotification={newNotificationsCount > 0}
        openNotifications={handleOpenNotifications}
      />
      <DrawerWithHeader
        headerLine={intl.formatMessage(
          {
            id:
              newNotificationsCount > 0
                ? 'customer-platform.header.user.notifications.title-number'
                : 'customer-platform.header.user.notifications.title',
          },
          { COUNT: newNotificationsCount },
        )}
        id="customer-platform.user.notifications"
        onClose={closeElement}
        open={isNotificationsDrawerOpen}
        hasPadding={false}
      >
        <NotificationsDrawerWrapper>
          {userNotificationsList &&
            userNotificationsList.data &&
            userNotificationsList?.data.length > 0 && (
              <UserNotificationsList
                notificationsList={userNotificationsList.data}
                closeNotifications={closeElement}
              />
            )}
          {userNotificationsList &&
            userNotificationsList.data &&
            userNotificationsList?.data.length === 0 && (
              <UserNotificationsEmpty />
            )}
        </NotificationsDrawerWrapper>
      </DrawerWithHeader>
    </>
  );
}
