import { PageWrapper } from '@frontend/ui-elements';
import { useIntl } from 'react-intl';
import ErrorSection from '@customer-platform-shared/components/ErrorSection/ErrorSection';
import PlatformHeader from '@customer-platform-shared/components/PlatformHeader/PlatformHeader';
import { HEADER_VARIANTS } from '@customer-platform-shared/components/PlatformHeader/PlatformHeader.types';

export function ErrorPage() {
  const intl = useIntl();

  return (
    <>
      <PlatformHeader variant={HEADER_VARIANTS.GUEST} />
      <PageWrapper>
        <ErrorSection
          id="generic-error-page"
          alignTop={true}
          title={intl.formatMessage({
            id: 'customer-platform.error-page.heading',
          })}
          description={intl.formatMessage({
            id: 'customer-platform.error-page.subheading',
          })}
          hideButtonIcon
        />
      </PageWrapper>
    </>
  );
}

export default ErrorPage;
