import {
  ButtonWrapper,
  ImageWrapper,
  MainWrapper,
  TitleSection,
  Wrapper,
} from './ErrorSection.css';
import { RiArrowRightUpLine } from '@remixicon/react';
import { Typography, useTheme } from '@mui/material';
import { Button, InnerTypography } from '@frontend/ui-elements';
import { IErrorSection } from './ErrorSection.types';
import errorImage from '@customer-platform-assets/images/error.png';

export function ErrorSection({
  id = 'error-page',
  title,
  description,
  imageSrc = errorImage,
  btnText,
  onClick,
  hideButtonIcon,
  alignTop = false,
}: IErrorSection) {
  const theme = useTheme();
  return (
    <MainWrapper alignTop={alignTop}>
      <Wrapper>
        <TitleSection>
          <Typography
            component="span"
            color={theme.palette.primary[500]}
            variant="h1"
          >
            {title}
          </Typography>

          <Typography variant="h5">
            <InnerTypography
              message={description}
              variant="h5"
            ></InnerTypography>
          </Typography>
        </TitleSection>

        {btnText && (
          <ButtonWrapper>
            <Button
              buttonType="primary"
              id={`${id}.button`}
              size="large"
              endIcon={!hideButtonIcon && <RiArrowRightUpLine size={24} />}
              onClick={onClick}
            >
              {btnText}
            </Button>
          </ButtonWrapper>
        )}
      </Wrapper>

      <ImageWrapper>
        <img src={imageSrc} alt="errorImage" />
      </ImageWrapper>
    </MainWrapper>
  );
}

export default ErrorSection;
