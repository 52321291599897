import {
  NotificationsMenuWrapper,
  NotificationsHeader,
} from './UserNotificationsDesktop.css';
import { IconButton } from '@frontend/ui-elements';
import { useIntl } from 'react-intl';
import { RiCloseLine } from '@remixicon/react';
import { theme } from '@frontend/theme';
import { Typography } from '@mui/material';
import { Translate } from '@frontend/translation';
import UserNotificationsBell from '../UserNotificationsBell/UserNotificationsBell';
import IUserNotificationsDesktop from './UserNotificationsDesktop.types';
import UserNotificationsEmpty from '../UserNotificationsEmpty/UserNotificationsEmpty';
import UserNotificationsList from '../UserNotificationsList/UserNotificationsList';
import { useNotifications } from '../useNotifications/useNotifications';
import { NOTIFICATIONS_POLLING_INTERVAL } from '@frontend/api';

export default function UserNotificationsDesktop({
  closeElement,
  currentOpenElement,
  isNotificationsOpen,
  openNotifications,
}: IUserNotificationsDesktop) {
  const intl = useIntl();
  const { userNotificationsList, newNotificationsCount } = useNotifications(
    NOTIFICATIONS_POLLING_INTERVAL,
  );

  const handleOpenNotifications = (
    event?: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event && openNotifications(event);
  };

  return (
    <>
      <UserNotificationsBell
        isNewNotification={newNotificationsCount > 0}
        openNotifications={handleOpenNotifications}
      />
      <NotificationsMenuWrapper
        anchorEl={
          isNotificationsOpen ? (currentOpenElement as HTMLElement) : undefined
        }
        onClose={closeElement}
        open={isNotificationsOpen}
        marginThreshold={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <NotificationsHeader>
          <Typography component="h5" variant="h5">
            <Translate id="customer-platform.header.user.notifications.title" />
            {newNotificationsCount > 0 && (
              <span> ({newNotificationsCount})</span>
            )}
          </Typography>

          <IconButton
            aria-label={intl.formatMessage({
              id: 'customer-platform.header.user.notifications.close',
            })}
            id="user-header.icon-button.close-user-notifications"
            data-testid="notifications-close-button"
            onClick={closeElement}
          >
            <RiCloseLine size={24} color={theme.palette.neutral[500]} />
          </IconButton>
        </NotificationsHeader>
        {userNotificationsList &&
          userNotificationsList.data &&
          userNotificationsList?.data.length > 0 && (
            <UserNotificationsList
              notificationsList={userNotificationsList.data}
              closeNotifications={closeElement}
            />
          )}
        {userNotificationsList &&
          userNotificationsList.data &&
          userNotificationsList?.data.length === 0 && (
            <UserNotificationsEmpty />
          )}
      </NotificationsMenuWrapper>
    </>
  );
}
