import { Typography } from '@mui/material';
import { isDate, formatDistanceToNowStrict } from 'date-fns';
import {
  NewNotificationBadge,
  NewNotificationWrapper,
  UserNotificationItemContent,
  UserNotificationItemFooter,
  UserNotificationItemHeader,
  UserNotificationItemWrapper,
} from './UserNotificationItem.css';
import IUserNotificationItem from './UserNotificationItem.types';
import { Button } from '@frontend/ui-elements';
import { Translate } from '@frontend/translation';

export default function UserNotificationItem({
  title,
  description,
  createdAt,
  isSeen,
  ctaText,
  onClick,
}: IUserNotificationItem) {
  const createdAtDate = new Date(createdAt);

  return (
    <UserNotificationItemWrapper>
      <UserNotificationItemHeader>
        <Typography variant="p1">{title}</Typography>
        <NewNotificationWrapper>
          <Typography variant="p4">
            {isDate(createdAtDate) &&
              formatDistanceToNowStrict(createdAtDate, { addSuffix: true })}
          </Typography>
          <NewNotificationBadge
            color="primary"
            variant="dot"
            invisible={isSeen}
          />
        </NewNotificationWrapper>
      </UserNotificationItemHeader>
      <UserNotificationItemContent>
        <Typography variant="p3">
          <Translate id={description} />
        </Typography>
      </UserNotificationItemContent>
      <UserNotificationItemFooter>
        <Button
          id="user-notifications.notification-item.button.view-notification"
          buttonType="primary"
          size="small"
          onClick={onClick}
        >
          <Translate id={ctaText} />
        </Button>
      </UserNotificationItemFooter>
    </UserNotificationItemWrapper>
  );
}
