import { useGetListings } from '@frontend/api';
import { FullPageLoader, PageWrapper } from '@frontend/ui-elements';
import { useMediaQuery, useTheme } from '@mui/material';
import { PageContent } from './SellerDashboard.css';
import PlatformHeader from '@customer-platform-shared/components/PlatformHeader/PlatformHeader';
import EmptyDashboard from '@customer-platform-shared/components/EmptyDashboard/EmptyDashboard';
import {
  Navigate,
  generatePath,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import DashboardHeader from '@customer-platform-shared/components/DashboardHeader/DashboardHeader';
import ListingList from './ListingDetails/ListingList/ListingList';
import { HEADER_VARIANTS } from '@customer-platform-shared/components/PlatformHeader/PlatformHeader.types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import EmptySearchResults from '@customer-platform-shared/components/DashboardSearch/EmptySearchResults/EmptySearchResults';
import ErrorPage from '../ErrorPage/ErrorPage';

export default function SellerDashboard() {
  const theme = useTheme();
  const location = useLocation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const [searchParams, _] = useSearchParams();
  const [showSearchError, setShowSearchError] = useState<boolean>(false);
  const intl = useIntl();

  useEffect(() => {
    setShowSearchError(
      searchParams.has('searchTerm') && !searchParams.has('searchItem'),
    );
  }, [searchParams]);

  const {
    isSuccess: isListingListSuccess,
    isPending: isListingListPending,
    isError: isRequestListError,
    data: listingListResponse,
  } = useGetListings();

  if (isListingListSuccess) {
    if (!listingListResponse?.data.length) {
      return (
        <>
          <PlatformHeader variant={HEADER_VARIANTS.SELLER} />
          <PageWrapper>
            <EmptyDashboard />
          </PageWrapper>
        </>
      );
    }
    if (isDesktop && !searchParams.has('searchTerm')) {
      const firstListing = listingListResponse?.data[0];
      const firstListingDetailsPage = generatePath(
        PLATFORM_ROUTES.listingDetails,
        { uuid: firstListing?.uuid },
      );
      return (
        <Navigate
          to={firstListingDetailsPage}
          state={{ ...location.state, disableScrollToCard: true }}
          replace
        />
      );
    } else {
      return (
        <>
          <PlatformHeader variant={HEADER_VARIANTS.SELLER} />
          <PageWrapper>
            <PageContent>
              <DashboardHeader
                cardsCount={listingListResponse.data.length}
                hideCardCount={showSearchError}
              />
              {showSearchError ? (
                <EmptySearchResults
                  title={intl.formatMessage({
                    id: 'customer-platform.search.error.heading',
                  })}
                  subtitle={intl.formatMessage(
                    { id: 'customer-platform.search.error.subHeading' },
                    {
                      RESULTS_ITEM_TYPE: intl.formatMessage({
                        id: 'customer-platform.search.error.sellerResultItemType',
                      }),
                    },
                  )}
                />
              ) : (
                <ListingList listings={listingListResponse.data} />
              )}
            </PageContent>
          </PageWrapper>
        </>
      );
    }
  }

  if (isListingListPending) {
    return <FullPageLoader />;
  }

  if (isRequestListError) {
    return <ErrorPage />;
  }
}
