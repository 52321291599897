import { useMatch } from 'react-router-dom';
import { OPERATION_MODE_TYPE_ENUM } from '@frontend/api';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { ROUTE_NAMES } from '@frontend/utils';

export const useGetOperationModeFromPath = () => {
  const buyerDashboardMatch = useMatch(PLATFORM_ROUTES.buyerDashboard);
  const buyerDashboardRoutesMatch = useMatch(
    `${PLATFORM_ROUTES.buyerDashboard}/*`,
  );
  const buyerDashboardRouteMatched =
    buyerDashboardMatch || buyerDashboardRoutesMatch;

  const currentVariantType = buyerDashboardRouteMatched
    ? ROUTE_NAMES.REQUEST
    : ROUTE_NAMES.LISTING;

  const currentOperationMode =
    currentVariantType === ROUTE_NAMES.REQUEST
      ? OPERATION_MODE_TYPE_ENUM.BUYER
      : OPERATION_MODE_TYPE_ENUM.SELLER;

  return currentOperationMode;
};
