import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const OrderPhotosDrawerHeader = styled(MUIBox)(() => ({
  marginBottom: '24px',
}));

export const OrderPhotosDrawerWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '534px',
  [theme.breakpoints.down('desktop')]: {
    width: '100%',
  },
}));

export const OrderPhotosDrawerInstructionsPanel = styled(MUIBox)(() => ({
  '& > div': {
    marginTop: '12px',
  },
}));
