import { Typography } from '@mui/material';
import { formatDateWithSpaces, formatNumberToUS } from '@frontend/utils';
import { Translate } from '@frontend/translation';
import { EmptySpace } from '@frontend/ui-elements';
import {
  Column,
  DataWrapper,
  MainWrapper,
  OrderValue,
  OrderValueLabel,
} from './RequestOrderCardDetails.css';
import { IRequestOrderCardDetails } from './RequestOrderCardDetails.types';
import BillOfLadingDetails from './BillOfLadingDetails/BillOfLadingDetails';
import {
  RiCalendar2Line,
  RiArrowUpDownLine,
  RiMoneyDollarCircleLine,
  RiMapPin2Line,
} from '@remixicon/react';
import ContainerList from './ContainerList/ContainerList';
import OrderDocuments from '../../DocumentList/OrderDocuments/OrderDocuments';
import PricePill from './PricePill/PricePill';
import { useParams } from 'react-router-dom';
import TimeLabelAndValue from './TimeLabelAndValue/TimeLabelAndValue';

export default function RequestOrderCardDetails({
  order,
  isRequestCancelled,
  refs: { documentsSectionRef, billOfLadingSectionRef, containerSectionRef },
}: IRequestOrderCardDetails) {
  const {
    quantity,
    shippingTo,
    cost,
    createdDate,
    expectedTimeOfDeparture,
    expectedTimeOfArrival,
    actualTimeOfDeparture,
    actualTimeOfArrival,
  } = order;

  const { orderId } = useParams();

  return (
    <MainWrapper>
      <DataWrapper>
        <Column>
          <OrderValue data-testid="weight-info">
            <OrderValueLabel>
              <RiArrowUpDownLine size={18} />
              <Typography variant="p1" component="span">
                <Translate id="customer-platform.request-details.order-card.weight" />
              </Typography>
            </OrderValueLabel>
            <Typography variant="p3" component="span">
              {formatNumberToUS(quantity)}
              <EmptySpace />
              <Translate id="customer-platform.request-details.order-card.weight-unit" />
            </Typography>
          </OrderValue>
          <OrderValue data-testid="destination-info">
            <OrderValueLabel>
              <RiMapPin2Line size={18} />
              <Typography variant="p1" component="span">
                <Translate id="customer-platform.request-details.order-card.destination" />
              </Typography>
            </OrderValueLabel>
            <Typography variant="p3" component="span">
              {shippingTo}
            </Typography>
          </OrderValue>
          <OrderValue data-testid="date-info">
            <OrderValueLabel>
              <RiCalendar2Line size={18} />
              <Typography variant="p1" component="span">
                <Translate id="customer-platform.request-details.order-card.date" />
              </Typography>
            </OrderValueLabel>
            <Typography variant="p3" component="span">
              {formatDateWithSpaces(createdDate)}
            </Typography>
          </OrderValue>
        </Column>

        <Column>
          <TimeLabelAndValue
            dataTestId="departure-times-info"
            actualTime={actualTimeOfDeparture}
            estimatedTime={expectedTimeOfDeparture}
            labelActual="customer-platform.request-details.order-card.actual-time-of-departure"
            labelEstimated="customer-platform.request-details.order-card.estimated-time-of-departure"
          />
          <TimeLabelAndValue
            dataTestId="arrival-times-info"
            actualTime={actualTimeOfArrival}
            estimatedTime={expectedTimeOfArrival}
            labelActual="customer-platform.request-details.order-card.actual-time-of-arrival"
            labelEstimated="customer-platform.request-details.order-card.estimated-time-of-arrival"
          />
          <OrderValue data-testid="price-info">
            {cost ? (
              <>
                <OrderValueLabel>
                  <RiMoneyDollarCircleLine size={18} />
                  <Typography variant="p1" component="span">
                    <Translate id="customer-platform.request-details.order-card.price" />
                  </Typography>
                </OrderValueLabel>
                <Typography variant="p3" component="span">
                  {formatNumberToUS(cost)}
                  <EmptySpace />
                  <Translate id="customer-platform.request-details.order-card.price-unit" />
                </Typography>
              </>
            ) : (
              <PricePill />
            )}
          </OrderValue>
        </Column>
      </DataWrapper>
      <BillOfLadingDetails
        order={order}
        isRequestCancelled={isRequestCancelled}
        billOfLadingSectionRef={billOfLadingSectionRef}
      />
      <OrderDocuments order={order} documentsSectionRef={documentsSectionRef} />
      {orderId === order.uuid && (
        <ContainerList
          order={order}
          containerSectionRef={containerSectionRef}
        />
      )}
    </MainWrapper>
  );
}
