import {
  IUserNotification,
  TNotificationBannerType,
  TNotificationSourceType,
} from '@frontend/api';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { generatePath } from 'react-router-dom';

interface NotificationDetails {
  title: string;
  description: string;
  cta: string;
}

const MNotificationBannerMap: Map<
  TNotificationBannerType,
  NotificationDetails
> = new Map([
  [
    'notification_banner.buyer.bol.reminder',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-order-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.bol-reminder',
      cta: 'customer-platform.header.user.notifications.list.item.cta.bol-reminder',
    },
  ],
  [
    'notification_banner.buyer.request.accepted',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-request-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.request-accepted',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.buyer.request.cancelled',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-request-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.request-cancelled',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.buyer.request.completed',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-request-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.request-completed',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.buyer.request.processed',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-request-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.request-processed',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.buyer.request.success',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-request-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.request-success',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.buyer.order.shipped',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-order-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.buyer.order-shipped',
      cta: 'customer-platform.header.user.notifications.list.item.cta.track-container',
    },
  ],
  [
    'notification_banner.buyer.document.review',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-order-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.document-review',
      cta: 'customer-platform.header.user.notifications.list.item.cta.review-documents',
    },
  ],
  [
    'notification_banner.seller.listing.accepted',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-listing-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.listing-accepted',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-listing-status',
    },
  ],
  [
    'notification_banner.seller.listing.cancelled',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-listing-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.listing-cancelled',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.seller.listing.completed',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-listing-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.listing-completed',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.seller.listing.processed',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-listing-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.listing-processed',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.seller.listing.success',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-listing-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.listing-success',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.seller.deal.signed',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-order-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.deal-signed',
      cta: 'customer-platform.header.user.notifications.list.item.cta.track-order',
    },
  ],
  [
    'notification_banner.seller.order.details_confirmed',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-order-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.order-details-confirmed',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.seller.order.loading_confirmed',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-order-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.order-loading-confirmed',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.seller.order.shipped',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.update-order-number',
      description:
        'customer-platform.header.user.notifications.list.item.desc.seller.order-shipped',
      cta: 'customer-platform.header.user.notifications.list.item.cta.view-status',
    },
  ],
  [
    'notification_banner.whatsapp_consent',
    {
      title:
        'customer-platform.header.user.notifications.list.item.title.whatsapp-consent',
      description:
        'customer-platform.header.user.notifications.list.item.desc.whatsapp-consent',
      cta: 'customer-platform.header.user.notifications.list.item.cta.whatsapp-accept-updates',
    },
  ],
  [
    'notification_banner.generic',
    {
      title: '',
      description: '',
      cta: '',
    },
  ],
]);

export function getNotificationDetails(type: TNotificationBannerType) {
  return MNotificationBannerMap.get(type);
}

const MNotificationRouteMap: Map<
  TNotificationSourceType,
  (notification: IUserNotification) => string
> = new Map([
  ['notification.source.user', () => ''],
  ['notification.source.company', () => ''],
  [
    'notification.source.request',
    (notification: IUserNotification): string => {
      return generatePath(PLATFORM_ROUTES.requestDetails, {
        uuid: notification.notificationSourceUuid,
      });
    },
  ],
  [
    'notification.source.listing',
    (notification: IUserNotification): string => {
      return generatePath(PLATFORM_ROUTES.listingDetails, {
        uuid: notification.notificationSourceUuid,
      });
    },
  ],
  [
    'notification.source.order',
    (notification: IUserNotification) => {
      if (
        notification.metadata &&
        Object.prototype.hasOwnProperty.call(
          notification.metadata,
          'requestUuid',
        )
      ) {
        return generatePath(PLATFORM_ROUTES.orderDetails, {
          uuid: notification.metadata?.requestUuid,
          orderId: notification.notificationSourceUuid,
        });
      }
      if (
        notification.metadata &&
        Object.prototype.hasOwnProperty.call(
          notification.metadata,
          'listingUuid',
        )
      ) {
        return generatePath(PLATFORM_ROUTES.listingOrderDetails, {
          uuid: notification.metadata.listingUuid,
          orderId: notification.notificationSourceUuid,
        });
      }

      return '';
    },
  ],
  ['notification.source.shipment', () => ''],
  ['notification.source.container', () => ''],
  ['notification.source.bill_of_lading', () => ''],
  ['notification.source.bidding', () => ''],
  ['notification.source.request.documents', () => ''],
  ['notification.source.shipment.documents', () => ''],
  ['notification.source.listing.documents', () => ''],
  ['notification.source.order.documents', () => ''],
  ['notification.source.container.documents', () => ''],
  ['notification.source.listing.material_photos', () => ''],
  ['notification.source.order.material_photos', () => ''],
]);

export function getNotificationRoute(type: TNotificationSourceType) {
  return MNotificationRouteMap.get(type);
}

export function getNotificationTitleTranslateVariables(
  type: TNotificationSourceType,
) {
  switch (type) {
    case 'notification.source.request':
      return {
        key: 'REQUEST_NUMBER',
        value: 'requestNumberId',
      };
    case 'notification.source.listing':
      return {
        key: 'LISTING_NUMBER',
        value: 'listingNumberId',
      };
    case 'notification.source.order':
      return {
        key: 'ORDER_NUMBER',
        value: 'orderNumberId',
      };
  }
}
