import { Typography } from '@mui/material';
import { Translate } from '@frontend/translation';
import { ToastAlert } from '@frontend/ui-elements';
import { RiInformationLine } from '@remixicon/react';
import ORDER_PHOTOS_ITEM_LIST from './OrderPhotosCheckList.const';
import { head } from 'lodash';
import {
  List,
  ListWrapper,
  MainWrapper,
  WarningMessageWrapper,
  WarningText,
} from './OrderPhotosCheckList.css';
import OrderPhotoCheckListItem from './OrderPhotosCheckListItem/OrderPhotoCheckListItem';
import { IOrderPhotosCheckList } from './OrderPhotosCheckList.types';

export default function OrderPhotosChecklist({
  alreadyUploadedFiles,
}: IOrderPhotosCheckList) {
  const areAllPhotosUploaded = ORDER_PHOTOS_ITEM_LIST.every(item =>
    alreadyUploadedFiles?.some(photo => photo.tag === item.tag),
  );

  const validatedPhotosCount = ORDER_PHOTOS_ITEM_LIST.reduce((count, item) => {
    const isValidated = alreadyUploadedFiles?.some(
      photo => photo.tag === item.tag,
    );
    return isValidated ? count + 1 : count;
  }, 0);

  return (
    <MainWrapper>
      <ListWrapper>
        <Typography variant="p1">
          <Translate id="customer-platform.listing-details.order-photos.checklist.title" />
        </Typography>

        <List disablePadding>
          {ORDER_PHOTOS_ITEM_LIST.length > 0 &&
            ORDER_PHOTOS_ITEM_LIST.map((listItem, index) => {
              const isUploaded = alreadyUploadedFiles?.some(
                photo => photo.tag === listItem.tag,
              );

              return (
                <OrderPhotoCheckListItem
                  key={index}
                  {...listItem}
                  isUploaded={isUploaded!}
                />
              );
            })}
        </List>
      </ListWrapper>
      {!areAllPhotosUploaded && validatedPhotosCount !== 0 && (
        <WarningMessageWrapper>
          <ToastAlert
            title={
              <Translate id="customer-platform.listing-details.order-photos.checklist.warning-title" />
            }
            message={
              <Translate
                id="customer-platform.listing-details.order-photos.checklist.warning-subtitle"
                values={{
                  span: (chunks: string[]) => (
                    <WarningText>{head(chunks)}</WarningText>
                  ),
                }}
              />
            }
            severity="warning"
            icon={<RiInformationLine size={24} />}
          />
        </WarningMessageWrapper>
      )}
    </MainWrapper>
  );
}
