import Cookies, { CookieAttributes } from 'js-cookie';
import TagManager from 'react-gtm-module';
import { v4 as uuid_v4 } from 'uuid';
import EVENTS from '../tracking/tracking.const';

const COOKIE_DOMAIN =
  import.meta.env.VITE_ENVIRONMENT !== 'dev' ? 'metycle.com' : undefined;
const COOKIE_NAME = 'MetycleAnalyticsProfile';
const PREVIOUS_PATH_COOKIE_KEY = 'metycle_previous_path';
const SESSION_ID_COOKIE_KEY = 'metycle_session_id';
const USER_ID_COOKIE_KEY = 'metycle_user_id';
const EXPIRATION_TIME = 1 / 24 / 2; // 30 minutes

const cookieConfig = {
  domain: COOKIE_DOMAIN,
  expires: EXPIRATION_TIME,
  secure: true,
} as CookieAttributes;

type TCookieKeys =
  | typeof PREVIOUS_PATH_COOKIE_KEY
  | typeof SESSION_ID_COOKIE_KEY
  | typeof USER_ID_COOKIE_KEY;

function getCookie(key: TCookieKeys) {
  const cookie = Cookies.get(COOKIE_NAME);
  const cookieData = cookie ? JSON.parse(cookie) : {};

  return cookieData[key] || undefined;
}

function setCookie(key: string, value: string) {
  const cookie = Cookies.get(COOKIE_NAME);
  const cookieData = cookie ? JSON.parse(cookie) : {};

  cookieData[key] = value;
  Cookies.set(COOKIE_NAME, JSON.stringify(cookieData), cookieConfig);
}

function removeCookie(key: string) {
  const cookie = Cookies.get(COOKIE_NAME);
  const cookieData = cookie ? JSON.parse(cookie) : {};

  delete cookieData[key];
  Cookies.set(COOKIE_NAME, JSON.stringify(cookieData), cookieConfig);
}

export function refreshCookieExpiration() {
  const sessionId = getSessionId();
  sessionId && setCookie(SESSION_ID_COOKIE_KEY, getSessionId());

  const previousPath = getPreviousPath();
  previousPath && setCookie(PREVIOUS_PATH_COOKIE_KEY, previousPath);

  const userId = getUserId();
  userId && setCookie(USER_ID_COOKIE_KEY, userId);
}

export function addCookieRefreshListeners() {
  document.body.addEventListener('click', refreshCookieExpiration);
  document.body.addEventListener('mousemove', refreshCookieExpiration);
  document.body.addEventListener('keydown', refreshCookieExpiration);
}

export function removeCookieRefreshListeners() {
  document.body.removeEventListener('click', refreshCookieExpiration);
  document.body.removeEventListener('mousemove', refreshCookieExpiration);
  document.body.removeEventListener('keydown', refreshCookieExpiration);
}

// PREV PATH
export function getPreviousPath() {
  return getCookie(PREVIOUS_PATH_COOKIE_KEY);
}

export function getPreviousPathAndUpdate(currentPage: string) {
  const previousPath = getPreviousPath();
  setCookie(PREVIOUS_PATH_COOKIE_KEY, currentPage);
  return previousPath;
}

// SESSION ID
export function getSessionId() {
  let sessionId = getCookie(SESSION_ID_COOKIE_KEY);

  if (!sessionId) {
    sessionId = uuid_v4();
    setCookie(SESSION_ID_COOKIE_KEY, sessionId);

    TagManager.dataLayer({
      dataLayer: Object.fromEntries(
        Object.entries({
          event: EVENTS.SESSION_START,
          session_id: sessionId,
          timestamp: Math.floor(
            (performance.timeOrigin + performance.now()) * 1000,
          ),
          user_id: getUserId(),
        }).filter(([_, value]) => value !== undefined),
      ),
    });
  }

  return sessionId;
}

// USER ID
export function getUserId() {
  return getCookie(USER_ID_COOKIE_KEY);
}

export function setUserId(userId: string) {
  setCookie(USER_ID_COOKIE_KEY, userId);
}

export function removeUserId() {
  removeCookie(USER_ID_COOKIE_KEY);
}
