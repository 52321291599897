import { useUpdateOperationMode } from '@frontend/api';
import { useAuth } from '@frontend/auth';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { DrawerWithHeader, Logo } from '@frontend/ui-elements';
import { useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  AppBar,
  Container,
  HeaderDrawerWrapper,
  Link,
  Toolbar,
  HeaderMobileMenuWrapper,
} from './PlatformHeader.css';
import { HEADER_VARIANTS, PlatformHeaderProps } from './PlatformHeader.types';
import useHeader from './useHeader/useHeader';
import useModal from '../Modals/useModal/useModal';
import LogoutModal from './LogoutModal/LogoutModal';
import HeaderBurgerMenu from './HeaderBurgerMenu/HeaderBurgerMenu';
import HeaderDesktopMenu from './HeaderDesktopMenu/HeaderDesktopMenu';
import VariantForm from '../Forms/VariantForm/VariantForm';
import SuccessToast from '../Toasts/SuccessToast/SuccessToast';
import CreateAdditionalRoleModal from '../Modals/CreateAdditionalRoleModal/CreateAdditionalRoleModal';
import UserProfile from '../UserProfile/UserProfile';
import UserNotificationsMobile from './UserNotifications/UserNotificationsMobile/UserNotificationsMobile';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function PlatformHeader({ variant }: PlatformHeaderProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { FEATURE_CUSTOMER_PLATFORM_NOTIFICATIONS } = useFlags();

  const {
    isAuthenticated,
    isCurrentUserBuyer,
    isCurrentUserSeller,
    isCurrentOperationModeBuyer,
    isCurrentOperationModeSeller,
    getCurrentUser,
  } = useAuth();

  const theme = useTheme();
  const intl = useIntl();
  const isTabletOrLess = useMediaQuery(theme.breakpoints.down('desktop'));
  const navigate = useNavigate();

  const [_, setResetStatus] = useState<boolean>(false);
  const [
    switchOperationModeWorkflowRequested,
    setSwitchOperationModeWorkflowRequested,
  ] = useState<boolean>(false);

  const userVariantPath =
    variant === HEADER_VARIANTS.BUYER ? 'request' : 'listing';

  const {
    currentOpenElement,
    openBurgerDrawer,
    openFormDrawer,
    openDropdownMenu,
    openLogoutModal,
    openToast,
    openProfileDrawer,
    openNotifications,
    openNotificationsDrawer,
    closeElement,
    closeToast,
    isBurgerDrawerOpen,
    isFormDrawerOpen,
    isDropdownMenuOpen,
    isLogoutModalOpen,
    isToastOpen,
    isNotificationsOpen,
    isNotificationsDrawerOpen,
  } = useHeader();

  const {
    openCreateAdditionalRoleModal,
    closeModal,
    isCreateAdditionalRoleModalOpen,
  } = useModal();

  const navigateToVariantHome = () => {
    return variant === HEADER_VARIANTS.BUYER
      ? PLATFORM_ROUTES.buyerDashboard
      : variant === HEADER_VARIANTS.SELLER
        ? PLATFORM_ROUTES.sellerDashboard
        : import.meta.env.VITE_STATIC_APP_URL;
  };

  const {
    mutate: updateOperationMode,
    isSuccess: isUpdateOperationModeSuccessful,
  } = useUpdateOperationMode();

  useEffect(() => {
    if (
      (variant === HEADER_VARIANTS.BUYER && isCurrentOperationModeSeller) ||
      (variant === HEADER_VARIANTS.SELLER && isCurrentOperationModeBuyer)
    ) {
      updateOperationMode();
    }
  }, [
    isCurrentOperationModeBuyer,
    isCurrentOperationModeSeller,
    updateOperationMode,
    variant,
  ]);

  useEffect(() => {
    if (isUpdateOperationModeSuccessful) {
      getCurrentUser && getCurrentUser();
      setResetStatus(prev => !prev);
      if (switchOperationModeWorkflowRequested) {
        navigate(
          variant === HEADER_VARIANTS.BUYER
            ? PLATFORM_ROUTES.sellerDashboard
            : PLATFORM_ROUTES.buyerDashboard,
        );
      }
      setSwitchOperationModeWorkflowRequested(false);
    }
    // Preventing navigate to be in the deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateOperationModeSuccessful, variant]);

  const switchDashboard = () => {
    navigate(
      variant === HEADER_VARIANTS.BUYER
        ? PLATFORM_ROUTES.sellerDashboard
        : PLATFORM_ROUTES.buyerDashboard,
    );
  };

  const switchOperationMode = useCallback(() => {
    getCurrentUser && getCurrentUser();
    setResetStatus(prev => !prev);

    setSwitchOperationModeWorkflowRequested(true);

    if (
      (variant === HEADER_VARIANTS.BUYER && isCurrentOperationModeBuyer) ||
      (variant === HEADER_VARIANTS.SELLER && isCurrentOperationModeSeller)
    ) {
      updateOperationMode();
    } else {
      setSwitchOperationModeWorkflowRequested(false);
      switchDashboard();
    }
  }, [
    getCurrentUser,
    isCurrentOperationModeBuyer,
    isCurrentOperationModeSeller,
    updateOperationMode,
    variant,
    setSwitchOperationModeWorkflowRequested,
  ]);

  const startOperationModeWorkflow = () => {
    if (isCurrentUserBuyer && isCurrentUserSeller) {
      switchDashboard();
    } else {
      openCreateAdditionalRoleModal();
    }
  };

  const closeFormAndOpenToast = () => {
    closeElement();
    openToast();
  };

  const openProfile = () => {
    openProfileDrawer();
    searchParams.set('profile', 'true');
    setSearchParams(searchParams);
  };

  return (
    <AppBar>
      <Container>
        <Toolbar id="header-toolbar">
          <Link id="user-header.link.logo" to={navigateToVariantHome()}>
            <Logo />
          </Link>

          {isTabletOrLess ? (
            <HeaderMobileMenuWrapper>
              {FEATURE_CUSTOMER_PLATFORM_NOTIFICATIONS && (
                <UserNotificationsMobile
                  closeElement={closeElement}
                  isNotificationsDrawerOpen={isNotificationsDrawerOpen}
                  openNotificationsDrawer={openNotificationsDrawer}
                />
              )}

              <HeaderBurgerMenu
                closeElement={closeElement}
                isBurgerDrawerOpen={isBurgerDrawerOpen}
                isLogoutModalOpen={isLogoutModalOpen}
                openBurgerDrawer={openBurgerDrawer}
                openFormDrawer={openFormDrawer}
                openLogoutModal={openLogoutModal}
                switchOperationMode={startOperationModeWorkflow}
                openProfileDrawer={openProfile}
                variant={variant}
              />
            </HeaderMobileMenuWrapper>
          ) : (
            <HeaderDesktopMenu
              closeElement={closeElement}
              currentOpenElement={currentOpenElement}
              isDropdownMenuOpen={isDropdownMenuOpen}
              isNotificationsOpen={isNotificationsOpen}
              openDropdownMenu={openDropdownMenu}
              openNotifications={openNotifications}
              openFormDrawer={openFormDrawer}
              openLogoutModal={openLogoutModal}
              switchOperationMode={startOperationModeWorkflow}
              openProfileDrawer={openProfile}
              variant={variant}
            />
          )}

          {isAuthenticated && (
            <DrawerWithHeader
              headerLine={intl.formatMessage({
                id: `customer-platform.header.${userVariantPath}-form.heading`,
              })}
              id="user-header.form-drawer"
              onClose={closeElement}
              open={isFormDrawerOpen}
            >
              <HeaderDrawerWrapper>
                <VariantForm
                  onSubmit={closeFormAndOpenToast}
                  variant={variant}
                />
              </HeaderDrawerWrapper>
            </DrawerWithHeader>
          )}

          {isLogoutModalOpen && (
            <LogoutModal
              onClose={isTabletOrLess ? openBurgerDrawer : closeElement}
            />
          )}

          {isToastOpen && (
            <SuccessToast
              isToastOpen={isToastOpen}
              closeToast={closeToast}
              titleId={`customer-platform.shared.components.user-header.success-toast.${userVariantPath}-form.title`}
              messageId={`customer-platform.shared.components.user-header.success-toast.${userVariantPath}-form.message`}
            />
          )}

          {isCreateAdditionalRoleModalOpen && (
            <CreateAdditionalRoleModal
              isOpen={true}
              onClose={closeModal}
              onAccountUpdated={switchOperationMode}
              newRoleName={intl.formatMessage({
                id: isCurrentUserSeller
                  ? 'customer-platform.shared.company.role.buyer.name'
                  : 'customer-platform.shared.company.role.seller.name',
              })}
            />
          )}

          {isAuthenticated && <UserProfile />}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
