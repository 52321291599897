import axiosClient from '../axiosClient';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { getQueryKey } from './getUserNotificationsList.const';
import {
  IGetUserNotificationsListResponseFailure,
  TGetUserNotificationsListResponse,
} from './getUserNotificationsList.types';
import { IOperationModeType } from '../getCurrentUser/getCurrentUser.types';

const submitGetUserNotificationsList = (
  apiUrl: string,
  operationMode: IOperationModeType,
) => {
  const queryParams = `?current_operations_mode=${operationMode}`;
  return axiosClient.get(`${apiUrl}/notifications/list/${queryParams}`);
};

const useGetUserNotificationsListQuery = (
  operationMode: IOperationModeType,
  refetchInterval?: number,
) => {
  const { apiUrl } = useContext(ApiContext);
  return useQuery<
    AxiosResponse<TGetUserNotificationsListResponse>,
    AxiosError<IGetUserNotificationsListResponseFailure>,
    AxiosResponse<TGetUserNotificationsListResponse>
  >({
    queryFn: () => submitGetUserNotificationsList(apiUrl, operationMode),
    queryKey: getQueryKey(operationMode),
    refetchInterval,
    refetchOnWindowFocus: true,
  });
};

export default useGetUserNotificationsListQuery;
