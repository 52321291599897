export const LISTING_ORDER_STATUS = [
  'order.status.signing_deals',
  'order.status.confirming_details',
  'order.status.loading',
  'order.status.shipment_completed',
];

export enum OrderLoadingPhotoCategory {
  EMPTY_CONTAINER = 'order.loading_photo.container_empty',
  HALF_FULL_CONTAINER = 'order.loading_photo.container_half_full',
  CLOSED_CONTAINER = 'order.loading_photo.container_closed',
  PHOTO_OF_THE_SEAL = 'order.loading_photo.photo_of_the_seal',
  NOT_VALIDATED = 'order.loading_photo.not_validated',
}
