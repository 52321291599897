import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export interface MainWrapperProps {
  extraTopPadding: boolean;
}

export const MainWrapper = styled(MUIBox, {
  shouldForwardProp: prop => prop !== 'removeTopPadding',
})<MainWrapperProps>(({ extraTopPadding }) => ({
  display: 'flex',
  padding: extraTopPadding ? '36px 0 24px 0' : '24px 0',
  flexDirection: 'column',
  gap: '16px',
  button: {
    alignSelf: 'start',
  },
}));

export const InfoItemsWrapper = styled(MUIBox)(() => ({
  flex: '1',
}));

export const InfoItem = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  borderBottom: `1px solid ${theme?.palette.neutral[50]}`,
  '&:last-of-type': {
    borderBottom: `none`,
  },
  paddingTop: '12px',
  paddingBottom: '12px',

  flexWrap: 'wrap',
  [theme.breakpoints.down('tablet')]: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
}));

export const InfoLabel = styled(MUIBox)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  flexWrap: 'wrap',
}));
