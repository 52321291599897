import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { Button, IconButton } from '@frontend/ui-elements';
import { useTheme } from '@mui/material';
import {
  BurgerDrawer,
  BurgerDrawerContainer,
  BurgerMenuBox,
  BurgerUserBox,
  BurgerUserMenuBox,
  GuessButtonWrapper,
  MenuItem,
} from './HeaderBurgerMenu.css';
import { HEADER_VARIANTS } from '../PlatformHeader.types';
import IHeaderBurgerMenu from './HeaderBurgerMenu.types';
import {
  RiArrowRightUpLine,
  RiExchangeFundsLine,
  RiUserLine,
} from '@remixicon/react';
import { RiCloseLine } from '@remixicon/react';
import { RiLogoutBoxRLine } from '@remixicon/react';
import { RiMenu4Fill } from '@remixicon/react';
import UserBox from '../UserBox/UserBox';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

export default function HeaderBurgerMenu({
  closeElement,
  isBurgerDrawerOpen,
  isLogoutModalOpen,
  openBurgerDrawer,
  openFormDrawer,
  openLogoutModal,
  switchOperationMode,
  openProfileDrawer,
  variant,
}: IHeaderBurgerMenu) {
  const { pathname } = useLocation();
  const theme = useTheme();
  const pathnameIsBuyMetal = pathname === PLATFORM_ROUTES.buyMetal;
  const pathnameIsNotSellMetal = pathname !== PLATFORM_ROUTES.sellMetal;
  const intl = useIntl();

  const BuyMetalButton = () => (
    <Button
      buttonType="primary"
      endIcon={<RiArrowRightUpLine size={24} />}
      id="user-header.button.go-to-buy-metal"
      href={PLATFORM_ROUTES.buyMetal}
      size="large"
    >
      <Translate id="customer-platform.header.user.menu.cta.guest-primary-cta" />
    </Button>
  );

  const OpenVariantFormDrawerButton = () => {
    const VARIANT_BUTTON_TYPE = {
      [HEADER_VARIANTS.BUYER]: 'request',
      [HEADER_VARIANTS.SELLER]: 'listing',
    };
    const getVariantType = VARIANT_BUTTON_TYPE[variant] || '';

    return (
      <Button
        buttonType="primary"
        endIcon={<RiArrowRightUpLine size={24} />}
        id={`user-header.button.open-create-${getVariantType}-drawer`}
        onClick={openFormDrawer}
        size="large"
      >
        <Translate
          id={`customer-platform.header.user.menu.cta.create-${getVariantType}`}
        />
      </Button>
    );
  };

  const SellMetalButton = () => (
    <Button
      buttonType={pathnameIsBuyMetal ? 'primary' : 'secondary'}
      endIcon={
        pathnameIsBuyMetal ? <RiArrowRightUpLine size={24} /> : undefined
      }
      id="user-header.button.go-to-sell-metal"
      href={PLATFORM_ROUTES.sellMetal}
      size="large"
    >
      <Translate id="customer-platform.header.user.menu.cta.guest-secondary-cta" />
    </Button>
  );

  return (
    <>
      {isBurgerDrawerOpen ? (
        <IconButton
          id="user-header.icon-button.close-burger"
          aria-label={intl.formatMessage({
            id: 'customer-platform.header.user.menu.close',
          })}
          onClick={closeElement}
        >
          <RiCloseLine size={24} color={theme.palette.neutral[500]} />
        </IconButton>
      ) : (
        <IconButton
          id="user-header.icon-button.open-burger"
          data-testid="user-dropdown-button"
          aria-label={intl.formatMessage({
            id: 'customer-platform.header.user.menu.open',
          })}
          onClick={openBurgerDrawer}
        >
          <RiMenu4Fill size={'24px'} color={theme.palette.neutral[500]} />
        </IconButton>
      )}

      <BurgerDrawer
        anchor="right"
        onClose={closeElement}
        open={isBurgerDrawerOpen || isLogoutModalOpen}
      >
        <BurgerDrawerContainer>
          {[HEADER_VARIANTS.BUYER, HEADER_VARIANTS.SELLER].includes(
            variant,
          ) && (
            <>
              <BurgerUserMenuBox>
                <BurgerUserBox>
                  <UserBox variant={variant} />
                </BurgerUserBox>
                <BurgerMenuBox>
                  <MenuItem
                    icon={<RiExchangeFundsLine size={16} />}
                    id="user-header.menu-item.switch-role"
                    onClick={switchOperationMode}
                  >
                    <Translate
                      id={
                        variant === HEADER_VARIANTS.BUYER
                          ? 'customer-platform.header.user.menu.menu-item.switch-to-seller-role'
                          : 'customer-platform.header.user.menu.menu-item.switch-to-buyer-role'
                      }
                    />
                  </MenuItem>
                  <MenuItem
                    icon={<RiUserLine size={16} />}
                    id="user-header.menu-item.profile"
                    onClick={openProfileDrawer}
                  >
                    <Translate id="customer-platform.header.user.menu.menu-item.profile" />
                  </MenuItem>
                  <MenuItem
                    icon={<RiLogoutBoxRLine size={16} />}
                    id="user-header.menu-item.logout"
                    onClick={openLogoutModal}
                  >
                    <Translate id="customer-platform.header.user.menu.menu-item.log-out" />
                  </MenuItem>
                </BurgerMenuBox>
              </BurgerUserMenuBox>
              <OpenVariantFormDrawerButton />
            </>
          )}

          {variant === HEADER_VARIANTS.GUEST && (
            <GuessButtonWrapper>
              {pathnameIsNotSellMetal && <SellMetalButton />}
              {!pathnameIsBuyMetal && <BuyMetalButton />}
            </GuessButtonWrapper>
          )}
        </BurgerDrawerContainer>
      </BurgerDrawer>
    </>
  );
}
