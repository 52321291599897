import { UserNotificationsListProps } from './UserNotificationsList.types';
import {
  NewNotificationsWrapper,
  NotificationsListTitle,
  OldNotificationsWrapper,
  UserNotificationsListWrapper,
} from './UserNotificationsList.css';
import UserNotificationItem from './UserNotificationItem/UserNotificationItem';
import { Typography } from '@mui/material';
import { Translate } from '@frontend/translation';
import { theme } from '@frontend/theme';
import { Button } from '@frontend/ui-elements';
import {
  IUserNotification,
  useUpdateUserNotificationsStatus,
} from '@frontend/api';
import {
  getNotificationDetails,
  getNotificationRoute,
  getNotificationTitleTranslateVariables,
} from './UserNotificationsList.utils';
import { useNavigate } from 'react-router-dom';
import { useGetOperationModeFromPath } from '@customer-platform-shared/utils/useGetOperationModeFromPath/useGetOperationModeFromPath';
export default function UserNotificationsList({
  notificationsList,
  closeNotifications,
}: UserNotificationsListProps) {
  const navigate = useNavigate();
  const currentOperationMode = useGetOperationModeFromPath();

  const {
    mutate: updateUserNotificationsStatus,
    isPending: isNotificationsStatusUpdating,
  } = useUpdateUserNotificationsStatus(currentOperationMode);

  const newNotifications = notificationsList.filter(
    notification => !notification.isSeen,
  );

  const oldNotifications = notificationsList.filter(
    notification => notification.isSeen,
  );

  const UserNotification = (notification: IUserNotification) => {
    const notificationDetails = getNotificationDetails(
      notification.notificationBannerType,
    );

    const notificationTitle = getNotificationTitleTranslateVariables(
      notification.notificationSourceType,
    );

    const translateValues = () => {
      if (notification.metadata) {
        if (
          notificationTitle &&
          notification.metadata[notificationTitle.value]
        ) {
          return {
            [notificationTitle.key]:
              notification.metadata[notificationTitle.value],
          };
        } else {
          return {};
        }
      }
      return {};
    };

    return (
      notificationDetails && (
        <UserNotificationItem
          title={
            <Translate
              id={notificationDetails.title}
              values={translateValues()}
            />
          }
          description={notificationDetails.description}
          createdAt={notification.createdAt}
          isSeen={notification.isSeen}
          ctaText={notificationDetails.cta}
          onClick={() => handleNotificationClick(notification)}
        />
      )
    );
  };

  const handleNotificationClick = (notification: IUserNotification) => {
    const notificationPage = getNotificationRoute(
      notification.notificationSourceType,
    )?.(notification);

    updateUserNotificationsStatus({
      uuid: notification.uuid,
      isSeen: true,
      operationMode: currentOperationMode,
    });
    navigate(`${notificationPage}`, { replace: true });
    closeNotifications();
  };

  const handleMarkAllAsRead = () => {
    updateUserNotificationsStatus({
      isSeen: true,
      operationMode: currentOperationMode,
    });
  };

  return (
    <>
      <UserNotificationsListWrapper>
        {newNotifications.length > 0 && (
          <NewNotificationsWrapper>
            <NotificationsListTitle>
              <Typography
                component="p"
                variant="p2"
                color={theme.palette.neutral[300]}
              >
                <Translate id="customer-platform.header.user.notifications.list.title.new" />
              </Typography>

              <Button
                buttonType="linked"
                id="user-notifications-list.button.mark-all-as-read"
                isSubmitting={isNotificationsStatusUpdating}
                onClick={handleMarkAllAsRead}
              >
                <Translate id="customer-platform.header.user.notifications.list.mark-all-as-read" />
              </Button>
            </NotificationsListTitle>
            {newNotifications.map(notification => (
              <UserNotification key={notification.uuid} {...notification} />
            ))}
          </NewNotificationsWrapper>
        )}

        {oldNotifications.length > 0 && (
          <OldNotificationsWrapper>
            <NotificationsListTitle>
              <Typography
                component="p"
                variant="p2"
                color={theme.palette.neutral[300]}
              >
                {newNotifications.length > 0 ? (
                  <Translate id="customer-platform.header.user.notifications.list.title.older" />
                ) : (
                  <Translate id="customer-platform.header.user.notifications.list.title.all" />
                )}
              </Typography>
            </NotificationsListTitle>
            {oldNotifications.map(notification => (
              <UserNotification key={notification.uuid} {...notification} />
            ))}
          </OldNotificationsWrapper>
        )}
      </UserNotificationsListWrapper>
    </>
  );
}
