import { IconButton } from '@frontend/ui-elements';
import { useIntl } from 'react-intl';
import { BellBadge } from './UserNotificationsBell.css';
import { RiNotification3Line } from '@remixicon/react';
import { theme } from '@frontend/theme';
import IUserNotificationsBell from './UserNotificationsBell.types';

export default function UserNotificationsBell({
  isNewNotification,
  openNotifications,
}: IUserNotificationsBell) {
  const intl = useIntl();
  return (
    <>
      <IconButton
        aria-label={intl.formatMessage({
          id: 'customer-platform.header.user.notifications.open',
        })}
        id="user-header.icon-button.open-user-notifications"
        onClick={openNotifications}
        data-testid="notifications-button"
      >
        <BellBadge
          variant="dot"
          color="error"
          invisible={!isNewNotification}
          overlap="circular"
        >
          <RiNotification3Line size={24} color={theme.palette.neutral[500]} />
        </BellBadge>
      </IconButton>
    </>
  );
}
