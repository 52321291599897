import { styled } from '@frontend/theme';
import { Box as MUIBox, Popover } from '@mui/material';

export const NotificationsMenuWrapper = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    width: '469px',
    height: 'calc(100vh - 124px)',
    marginTop: '48px',
    boxShadow: theme.shadows[6],
  },
}));

export const NotificationsHeader = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '23px',
  paddingRight: '24px',
  paddingBottom: '23px',
  paddingLeft: '20px',
  borderBottom: `1px solid ${theme.palette.neutral[50]}`,

  [theme.breakpoints.down('tabletL')]: {
    paddingLeft: '24px',
  },
  [theme.breakpoints.down('tablet')]: {
    paddingLeft: '20px',
  },
}));
