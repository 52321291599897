export enum EHeaderElement {
  BURGER = 'burger',
  DROPDOWN = 'dropdown',
  FORM = 'form',
  LOGOUT = 'logout',
  TOAST = 'toast',
  PROFILE = 'profile',
  USER_BOX = 'user-box',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_Drawer = 'notifications-drawer',
}

export type THeaderElement =
  (typeof EHeaderElement)[keyof typeof EHeaderElement];
