import { OrderLoadingPhotoCategory } from '@frontend/api';
import { IOrderPhotosListItem } from './OrderPhotosCheckList.types';

const ORDER_PHOTOS_ITEM_LIST = [
  {
    translationId:
      'customer-platform.listing-details.order-photos.checklist.empty-container',
    tag: OrderLoadingPhotoCategory.EMPTY_CONTAINER,
  },
  {
    translationId:
      'customer-platform.listing-details.order-photos.checklist.half-full-container',
    tag: OrderLoadingPhotoCategory.HALF_FULL_CONTAINER,
  },
  {
    translationId:
      'customer-platform.listing-details.order-photos.checklist.closed-container',
    tag: OrderLoadingPhotoCategory.CLOSED_CONTAINER,
  },
  {
    translationId:
      'customer-platform.listing-details.order-photos.checklist.seal-container',
    tag: OrderLoadingPhotoCategory.PHOTO_OF_THE_SEAL,
  },
] as IOrderPhotosListItem[];

export default ORDER_PHOTOS_ITEM_LIST;
