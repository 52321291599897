import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const UserNotificationsListWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const NewNotificationsWrapper = styled(MUIBox)(() => ({}));

export const OldNotificationsWrapper = styled(MUIBox)(() => ({}));

export const NotificationsListTitle = styled(MUIBox)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 20px',
}));
