import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const NotificationsEmpty = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '472px',
  padding: '16px 20px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',

  [theme.breakpoints.down('desktop')]: {
    padding: '16px 24px',
  },

  [theme.breakpoints.down('tabletL')]: {
    height: '100vh',
    padding: '16px 20px',
  },
}));
