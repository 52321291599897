import { OrderLoadingPhotoCategory } from './getListingOrderList.const';
import { IListingOrder } from './getListingOrderList.types';

export const mockedListingOrder: IListingOrder = {
  uuid: 'db4c2068-8a72-41bd-967e-90892832d356',
  status: 'order.status.confirming_details',
  publicId: '2PHH30Z',
  quantity: 16,
  shippingTo: 'Germany',
  createdDate: '2024-09-04T14:11:19.224397+00:00',
  orderNumberId: '147',
  sellerIncoterms: 'incoterms.cif',
  paymentTerms: 'abc',
  value: 150,
  documents: [],
  photos: [
    {
      title: 'metal-1.jpg',
      tag: OrderLoadingPhotoCategory.EMPTY_CONTAINER,
      uniqueIdentifier: '205ef32b-5ca2-47b4-8c55-df94ecebb1e6',
      fileType: 'jpg',
    },
    {
      title: 'metal-2.jpg',
      tag: OrderLoadingPhotoCategory.HALF_FULL_CONTAINER,
      uniqueIdentifier: '205ef32b-5ca2-47b4-8c55-df94ecebb1e7',
      fileType: 'jpg',
    },
    {
      title: 'metal-3.jpg',
      tag: OrderLoadingPhotoCategory.CLOSED_CONTAINER,
      uniqueIdentifier: '205ef32b-5ca2-47b4-8c55-df94ecebb1e8',
      fileType: 'jpg',
    },
    {
      title: 'metal-4.jpg',
      tag: OrderLoadingPhotoCategory.PHOTO_OF_THE_SEAL,
      uniqueIdentifier: '205ef32b-5ca2-47b4-8c55-df94ecebb1e9',
      fileType: 'jpg',
    },
  ],
};

export const mockedListingOrderWithMissingRequiredPhotos: IListingOrder = {
  ...mockedListingOrder,
  photos: [
    {
      title: 'metal-1.jpg',
      tag: OrderLoadingPhotoCategory.EMPTY_CONTAINER,
      uniqueIdentifier: '205ef32b-5ca2-47b4-8c55-df94ecebb1e6',
      fileType: 'jpg',
    },
    {
      title: 'metal-2.jpg',
      tag: OrderLoadingPhotoCategory.HALF_FULL_CONTAINER,
      uniqueIdentifier: '205ef32b-5ca2-47b4-8c55-df94ecebb1e7',
      fileType: 'jpg',
    },
  ],
};

export const mockedListingOrderWithNoRequiredPhotos: IListingOrder = {
  ...mockedListingOrder,
  photos: [
    {
      title: 'metal-1.jpg',
      tag: OrderLoadingPhotoCategory.NOT_VALIDATED,
      uniqueIdentifier: '205ef32b-5ca2-47b4-8c55-df94ecebb1e6',
      fileType: 'jpg',
    },
    {
      title: 'metal-2.jpg',
      tag: OrderLoadingPhotoCategory.NOT_VALIDATED,
      uniqueIdentifier: '205ef32b-5ca2-47b4-8c55-df94ecebb1e7',
      fileType: 'jpg',
    },
  ],
};
