import {
  ICreateSessionRequest,
  IEmailVerificationPayload,
  useCreateSession,
  useDestroySession,
  useGetEmailVerification,
  useGetCurrentUser,
  USER_ROLE_TYPE_ENUM,
  OPERATION_MODE_TYPE_ENUM,
} from '@frontend/api';
import { getUserId, removeUserId, setUserId } from '@frontend/tracking';
import { setUser as setSentryUser } from '@sentry/react';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import {
  AUTH_ACTION_TYPES,
  IAuthContext,
  IAuthContextProvider,
} from './AuthContext.types';
import { authReducer } from './AuthContext.utils';

export const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  authenticationInProgress: false,
  authenticationUnexpectedError: false,
  checkSessionInProgress: false,
  isEmailVerificationInProgress: false,
  isEmailVerificationNotStarted: true,
  isEmailVerificationSuccess: false,
  isEmailVerificationFail: false,
  isCurrentUserBuyer: false,
  isCurrentUserSeller: false,
  isCurrentOperationModeBuyer: false,
  isCurrentOperationModeSeller: false,
});

export default function AuthContextProvider({
  children,
}: IAuthContextProvider) {
  const {
    mutate: createSession,
    isSuccess: isCreateSessionSuccess,
    isError: isCreateSessionError,
  } = useCreateSession();

  const { mutate: destroySession } = useDestroySession();

  const {
    refetch: getCurrentUser,
    isSuccess: getCurrentUserSucceeded,
    isError: getCurrentUserFailed,
    failureReason: getCurrentUserFailure,
    data: currentUserResponse,
  } = useGetCurrentUser();

  const {
    mutate: verifyEmail,
    isSuccess: emailVerificationStatusSucceeded,
    isError: emailVerificationStatusFailed,
  } = useGetEmailVerification();

  const [state, dispatch] = useReducer(authReducer, {
    authenticationInProgress: false,
    authenticationSucceeded: false,
    authenticationFailed: false,
    authenticationFailedWithUnexpectedError: false,
    checkSessionInProgress: false,
    checkSessionSucceeded: false,
    checkSessionFailed: false,
    emailVerificationNotStarted: true,
    emailVerificationInProgress: false,
    emailVerificationSucceeded: false,
    emailVerificationFailed: false,
  });

  const login = useCallback(
    (createSessionRequest: ICreateSessionRequest) => {
      dispatch({ type: AUTH_ACTION_TYPES.LOGIN_PROGRESS });
      createSession(createSessionRequest);
    },
    [createSession],
  );

  const logout = useCallback(() => {
    destroySession();
    removeUserId();
    dispatch({ type: AUTH_ACTION_TYPES.LOGOUT });
  }, [destroySession]);

  const checkSession = useCallback(() => {
    dispatch({ type: AUTH_ACTION_TYPES.CHECK_SESSION_PROGRESS });
    getCurrentUser();
  }, [getCurrentUser]);

  const emailVerification = useCallback(
    (emailVerificationPayload: IEmailVerificationPayload) => {
      dispatch({ type: AUTH_ACTION_TYPES.EMAIL_VERIFICATION_PROGRESS });
      verifyEmail(emailVerificationPayload);
    },
    [verifyEmail],
  );

  const isCurrentOperationModeBuyer = useCallback(() => {
    return (
      !!state.currentUser &&
      state.currentUser.currentOperationsMode === OPERATION_MODE_TYPE_ENUM.BUYER
    );
  }, [state.currentUser]);

  const isCurrentOperationModeSeller = useCallback(() => {
    return (
      !!state.currentUser &&
      state.currentUser.currentOperationsMode ===
        OPERATION_MODE_TYPE_ENUM.SELLER
    );
  }, [state.currentUser]);

  const isCurrentUserBuyer = useCallback(() => {
    return (
      !!state.currentUser &&
      [
        USER_ROLE_TYPE_ENUM.BUYER,
        USER_ROLE_TYPE_ENUM.SELLER_AND_BUYER,
      ].includes(state.currentUser.role)
    );
  }, [state.currentUser]);

  const isCurrentUserSeller = useCallback(() => {
    return (
      !!state.currentUser &&
      [
        USER_ROLE_TYPE_ENUM.SELLER,
        USER_ROLE_TYPE_ENUM.SELLER_AND_BUYER,
      ].includes(state.currentUser.role)
    );
  }, [state.currentUser]);

  useEffect(() => {
    if (isCreateSessionSuccess) {
      getCurrentUser();
    } else if (isCreateSessionError) {
      dispatch({ type: AUTH_ACTION_TYPES.LOGIN_FAILED });
    }
  }, [getCurrentUser, isCreateSessionError, isCreateSessionSuccess]);

  useEffect(() => {
    if (emailVerificationStatusSucceeded) {
      dispatch({ type: AUTH_ACTION_TYPES.EMAIL_VERIFICATION_SUCCESS });
      getCurrentUser();
    } else if (emailVerificationStatusFailed) {
      dispatch({ type: AUTH_ACTION_TYPES.EMAIL_VERIFICATION_FAILED });
    }
  }, [
    getCurrentUser,
    emailVerificationStatusSucceeded,
    emailVerificationStatusFailed,
  ]);

  useEffect(() => {
    if (getCurrentUserSucceeded) {
      dispatch({
        type: AUTH_ACTION_TYPES.LOGIN_SUCCESS,
        payload: currentUserResponse?.data,
      });
      // Register userID for tracking
      const recordedUser = getUserId();
      setSentryUser({
        id: currentUserResponse.data.uuid,
      });
      if (
        currentUserResponse &&
        (!recordedUser || recordedUser !== currentUserResponse.data.uuid)
      ) {
        setUserId(currentUserResponse.data.uuid);
      }
    } else if (getCurrentUserFailed) {
      if (
        getCurrentUserFailure &&
        getCurrentUserFailure.response &&
        getCurrentUserFailure.response.status === 403
      ) {
        dispatch({ type: AUTH_ACTION_TYPES.LOGIN_FAILED });
      } else {
        dispatch({ type: AUTH_ACTION_TYPES.LOGIN_UNEXPECTED_ERROR });
      }
    }
  }, [currentUserResponse, getCurrentUserFailed, getCurrentUserSucceeded]);

  return (
    <AuthContext.Provider
      value={{
        login,
        emailVerification,
        logout,
        checkSession,
        getCurrentUser,
        currentUser: state.currentUser,
        isCurrentUserBuyer: isCurrentUserBuyer(),
        isCurrentUserSeller: isCurrentUserSeller(),
        isCurrentOperationModeBuyer: isCurrentOperationModeBuyer(),
        isCurrentOperationModeSeller: isCurrentOperationModeSeller(),
        isAuthenticated: !!state.currentUser,
        authenticationInProgress: state.authenticationInProgress,
        authenticationUnexpectedError:
          state.authenticationFailedWithUnexpectedError,
        checkSessionInProgress: state.checkSessionInProgress,
        isEmailVerificationInProgress: state.emailVerificationInProgress,
        isEmailVerificationNotStarted: state.emailVerificationNotStarted,
        isEmailVerificationSuccess: state.emailVerificationSucceeded,
        isEmailVerificationFail: state.emailVerificationFailed,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
