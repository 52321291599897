import { useState } from 'react';
import { createPortal } from 'react-dom';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { Button } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  InfoLabel,
  InfoItemsWrapper,
  InfoItem,
  MainWrapper,
} from './BillOfLadingDetails.css';
import {
  IBillOfLadingDetails,
  IBillOfLadingInfoItem,
} from './BillOfLadingDetails.types';
import { RiArrowRightLine } from '@remixicon/react';
import BillOfLadingDrawer from './BillOfLadingDrawer/BillOfLadingDrawer';
import { RiCodepenLine } from '@remixicon/react';
import { RiInformationLine } from '@remixicon/react';
import { RiMapPin2Line } from '@remixicon/react';
import { RiMapPin4Line } from '@remixicon/react';
import { RiMenuAddLine } from '@remixicon/react';
import { RiMenuLine } from '@remixicon/react';
import { RiRoadMapLine } from '@remixicon/react';
import SuccessToast from '@customer-platform-shared/components/Toasts/SuccessToast/SuccessToast';
import { RiTimeLine } from '@remixicon/react';

import { RiUserLine } from '@remixicon/react';

const BillOfLadingInfoItem = ({
  id,
  icon,
  label,
  value,
}: IBillOfLadingInfoItem) => {
  return (
    <InfoItem data-testid={id}>
      <InfoLabel>
        {icon}
        <Typography variant="p1" component="span">
          {label}
        </Typography>
      </InfoLabel>
      <Typography variant="p3" component="span">
        {value}
      </Typography>
    </InfoItem>
  );
};

export default function BillOfLadingDetails({
  order,
  isRequestCancelled,
  billOfLadingSectionRef,
}: IBillOfLadingDetails) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const navigate = useNavigate();
  const { uuid: requestId } = useParams();
  const [isBolUpdateSuccessful, setIsBolUpdateSuccessful] =
    useState<boolean>(false);

  const { uuid: orderId, billOfLading } = order;
  const headerPortal = document.getElementById('header-toolbar');

  const {
    dischargePort,
    additionalRemarks,
    consigneeAdditionalInformation,
    consigneeAddress,
    consigneeName,
    finalDestination,
    freeTime,
    hsnCode,
    notifyName,
    notifyAddress,
    materialDescription,
  } = billOfLading || {};

  const handleOpenBillOfLadingForm = () => {
    const billOfLadingFormUrl = generatePath(
      PLATFORM_ROUTES.updateBillOfLading,
      { uuid: requestId as string, orderId: orderId as string },
    );
    navigate(billOfLadingFormUrl, { replace: true });
  };

  const handleBillOfLadingSuccess = () => setIsBolUpdateSuccessful(true);
  const handleBillOfLadingSuccessMessageClose = () =>
    setIsBolUpdateSuccessful(false);

  const billOfLadingInfoItems = [
    {
      icon: <RiUserLine size={18} />,
      label: (
        <Translate id="customer-platform.request-details.bill-of-lading.consignee-name" />
      ),
      id: 'consignee-name',
      value: consigneeName,
    },
    {
      icon: <RiMapPin2Line size={18} />,
      label: (
        <Translate id="customer-platform.request-details.bill-of-lading.consignee-address" />
      ),
      id: 'consignee-address',
      value: consigneeAddress,
    },
    {
      icon: <RiInformationLine size={18} />,
      label: (
        <Translate id="customer-platform.request-details.bill-of-lading.consignee-additional-information" />
      ),
      id: 'consignee-additional-information',
      value: consigneeAdditionalInformation,
    },
    {
      icon: <RiUserLine size={18} />,
      label: (
        <Translate id="customer-platform.request-details.bill-of-lading.notify-name" />
      ),
      id: 'notify-name',
      value: notifyName,
    },
    {
      icon: <RiMapPin2Line size={18} />,
      label: (
        <Translate id="customer-platform.request-details.bill-of-lading.notify-address" />
      ),
      id: 'notify-address',
      value: notifyAddress,
    },
    {
      icon: <RiMenuLine size={18} />,
      label: (
        <Translate id="customer-platform.request-details.bill-of-lading.material-description" />
      ),
      id: 'material-description',
      value: materialDescription,
    },
    {
      icon: <RiCodepenLine size={18} />,
      label: (
        <Translate id="customer-platform.request-details.bill-of-lading.hsn-code" />
      ),
      id: 'hsn-code',
      value: hsnCode,
    },
    {
      icon: <RiMapPin4Line size={18} />,
      label: (
        <Translate id="customer-platform.request-details.bill-of-lading.discharge-port" />
      ),
      id: 'discharge-port',
      value: dischargePort,
    },
    {
      icon: <RiRoadMapLine size={18} />,
      label: (
        <Translate id="customer-platform.request-details.bill-of-lading.final-destination" />
      ),
      id: 'final-destination',
      value: finalDestination,
    },
    {
      icon: <RiTimeLine size={18} />,
      label: (
        <Translate id="customer-platform.request-details.bill-of-lading.free-time" />
      ),
      id: 'free-time',
      value: freeTime,
    },
    {
      icon: <RiMenuAddLine size={18} />,
      label: (
        <Translate id="customer-platform.request-details.bill-of-lading.additional-marks" />
      ),
      id: 'additional-marks',
      value: additionalRemarks,
    },
  ] as IBillOfLadingInfoItem[];

  return (
    <MainWrapper ref={billOfLadingSectionRef}>
      <Typography variant={isMobile ? 'h4' : 'h5'}>
        <Translate id="customer-platform.request-details.bill-of-lading.heading" />
      </Typography>
      <InfoItemsWrapper>
        {billOfLadingInfoItems.every(item => !item.value) && (
          <Typography variant="p1">
            <Translate id="customer-platform.request-details.bill-of-lading.empty" />
          </Typography>
        )}
        {billOfLadingInfoItems.map(
          item =>
            item.value && (
              <BillOfLadingInfoItem
                key={item.id + item.value}
                icon={item.icon}
                label={item.label}
                id={item.id}
                value={item.value}
              />
            ),
        )}
      </InfoItemsWrapper>
      {headerPortal && (
        <>
          {createPortal(
            <SuccessToast
              isToastOpen={isBolUpdateSuccessful}
              closeToast={handleBillOfLadingSuccessMessageClose}
              titleId="customer-platform.request-details.bill-of-lading.form.success-toast.title"
              messageId="customer-platform.request-details.bill-of-lading.form.success-toast.message"
            />,
            headerPortal,
          )}
        </>
      )}

      <BillOfLadingDrawer order={order} onSuccess={handleBillOfLadingSuccess} />
      {!isRequestCancelled && (
        <Button
          buttonType="primary"
          id="bill-of-lading-details.button.open-form"
          size="large"
          onClick={handleOpenBillOfLadingForm}
          endIcon={<RiArrowRightLine size={24} />}
        >
          {billOfLading ? (
            <Translate id="customer-platform.request-details.bill-of-lading.edit-bol-cta" />
          ) : (
            <Translate id="customer-platform.request-details.bill-of-lading.new-bol-cta" />
          )}
        </Button>
      )}
    </MainWrapper>
  );
}
