import { styled } from '@frontend/theme';
import { Box as MUIBox, List as MUIList } from '@mui/material';

export const List = styled(MUIList)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  alignItems: 'flex-start',
  justifyItems: 'center',
}));

export const ListWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'flex-start',
  justifyItems: 'center',
  width: '100%',
}));

export const MainWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'flex-start',
  width: '100%',
}));

export const WarningMessageWrapper = styled(MUIBox)(() => ({
  width: '100%',
}));

export const WarningText = styled('span')(() => ({
  fontWeight: 500,
  textDecoration: 'underline',
}));
