import { useState, useEffect } from 'react';
import { useGetUserNotificationsList } from '@frontend/api';
import { useGetOperationModeFromPath } from '@customer-platform-shared/utils/useGetOperationModeFromPath/useGetOperationModeFromPath';

export const useNotifications = (pollingInterval = 0) => {
  const currentOperationMode = useGetOperationModeFromPath();

  const {
    isSuccess: getUserNotificationsListSuccess,
    data: userNotificationsList,
  } = useGetUserNotificationsList(currentOperationMode, pollingInterval);

  const [newNotificationsCount, setNewNotificationsCount] = useState(0);

  useEffect(() => {
    if (userNotificationsList && userNotificationsList.data.length > 0) {
      const unseenCount = userNotificationsList.data.filter(
        notification => notification.isSeen === false,
      ).length;

      setNewNotificationsCount(unseenCount);
    }
  }, [getUserNotificationsListSuccess, userNotificationsList]);

  return {
    userNotificationsList,
    newNotificationsCount,
    getUserNotificationsListSuccess,
  };
};
