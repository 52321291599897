import { SearchAutocompleteSt, SearchBarWrapper } from './SearchBar.css';
import { AutocompleteRenderInputParams, InputAdornment } from '@mui/material';
import { RiSearchLine } from '@remixicon/react';
import { theme } from '@frontend/theme';
import { TextFieldSt } from '../Select/Select.css';
import {
  ISearchableItem,
  SEARCHABLE_ITEM_TYPE,
  SearchBarProps,
} from './SearchBar.types';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

const SearchBarInput = function ({
  params,
  placeholder,
}: {
  params: AutocompleteRenderInputParams;
  placeholder: string;
}) {
  return (
    <span>
      <TextFieldSt
        {...params}
        placeholder={placeholder}
        slotProps={{
          htmlInput: {
            ...params.inputProps,
            'data-testid': 'searchInput',
          },
          input: {
            ...params.InputProps,
            style: {
              padding: '12px 16px',
              height: '47px',
              fontSize: '16px',
            },
            startAdornment: (
              <InputAdornment position="start">
                <RiSearchLine size={24} color={theme.palette.neutral[200]} />
              </InputAdornment>
            ),
          },
        }}
      />
    </span>
  );
};

function highlightSubstring(fullString: string, substring: string): string {
  const sanitizedString = fullString.replace(/(<([^>]+)>)/gi, '');
  if (
    !sanitizedString.toLowerCase().includes(substring.toLowerCase()) ||
    substring === ''
  ) {
    return sanitizedString;
  }
  const regex = new RegExp(`(${substring})`, 'gi');
  return sanitizedString.replace(regex, '<b>$1</b>');
}

const SearchBar = function ({
  onSelect,
  searchableItems,
  placeholder,
}: SearchBarProps) {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [searchParams, _] = useSearchParams();
  const intl = useIntl();

  useEffect(() => {
    if (searchParams.has('searchTerm')) {
      setInputValue(searchParams.get('searchTerm') ?? '');
    }
  }, [searchParams]);

  function getPrefixFromElementType(elementType: ISearchableItem): string {
    switch (elementType.itemType) {
      case SEARCHABLE_ITEM_TYPE.REQUEST:
        return intl.formatMessage({
          id: 'customer-platform.search.item-prefix.request',
        });
      case SEARCHABLE_ITEM_TYPE.LISTING:
        return intl.formatMessage({
          id: 'customer-platform.search.item-prefix.listing',
        });
      case SEARCHABLE_ITEM_TYPE.LISTING_ORDER:
      case SEARCHABLE_ITEM_TYPE.REQUEST_ORDER:
        return intl.formatMessage({
          id: 'customer-platform.search.item-prefix.order',
        });
    }
    return '';
  }

  return (
    <SearchBarWrapper>
      <SearchAutocompleteSt
        disablePortal
        autoHighlight
        freeSolo
        onChange={(event, value) => {
          if (value) {
            typeof value === 'string'
              ? onSelect(null, value)
              : onSelect(
                  value as ISearchableItem,
                  (value as ISearchableItem).label,
                );
          } else {
            onSelect(null, '');
          }
        }}
        open={open}
        onClose={() => setOpen(false)}
        options={searchableItems}
        sx={{ width: '100%', height: '48px' }}
        clearOnBlur={false}
        disableCloseOnSelect={false}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          setOpen(!!newInputValue);
          if (newInputValue === '') {
            onSelect(null, '');
          }
        }}
        renderInput={params => (
          <SearchBarInput params={params} placeholder={placeholder} />
        )}
        renderOption={({ key, ...props }, option: unknown, { inputValue }) => (
          <li key={(option as ISearchableItem)['id'] + '-' + key} {...props}>
            {getPrefixFromElementType(option as ISearchableItem)}
            &nbsp;
            <span
              dangerouslySetInnerHTML={{
                __html: highlightSubstring(
                  (option as ISearchableItem)['label'],
                  inputValue,
                ),
              }}
            />
          </li>
        )}
        slotProps={{
          listbox: {
            style: {
              paddingTop: 0,
              paddingBottom: 0,
              border: `1px solid ${theme?.palette.neutral[50]}`,
              borderTop: '0',
            },
          },
          paper: {
            style: {
              borderRadius: 0,
            },
          },
        }}
      />
    </SearchBarWrapper>
  );
};

export default SearchBar;
