import { styled } from '@frontend/theme';

import { Badge, Box as MUIBox } from '@mui/material';

export const UserNotificationItemWrapper = styled(MUIBox)(({ theme }) => ({
  padding: '8px 20px 16px 20px',
  borderBottom: `1px solid ${theme.palette.neutral[50]}`,
  '&:last-of-type': {
    borderBottom: 'none',
  },
}));

export const UserNotificationItemHeader = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.palette.neutral[500],
}));

export const UserNotificationItemContent = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '4px',
}));

export const UserNotificationItemFooter = styled(MUIBox)(() => ({
  display: 'flex',
  marginTop: '8px',
}));

export const NewNotificationWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: theme.palette.neutral[300],
}));

export const NewNotificationBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    marginTop: '1px',
  },
}));
