import {
  IProfileFormSchema,
  useGetUserProfile,
  useUpdateProfile,
} from '@frontend/api';
import {
  EditButtonWrapper,
  HeaderDrawerWrapper,
  LoaderWrapper,
  UserProfileWrapper,
} from './UserProfile.css';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import ProfileCompanyDetails from './ProfileCompanyDetails/ProfileCompanyDetails';
import { Button, DrawerWithHeader, ToastAlert } from '@frontend/ui-elements';
import { Translate } from '@frontend/translation';
import { RiAlertLine } from '@remixicon/react';
import ProfileForm from '../Forms/ProfileForm/ProfileForm';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import FormType from '../Forms/utils/FormType.types';
import { createPortal } from 'react-dom';
import SuccessToast from '../Toasts/SuccessToast/SuccessToast';
import InfoModal from '../Modals/InfoModal/InfoModal';
import { useIntl } from 'react-intl';
import useHeader from '../PlatformHeader/useHeader/useHeader';
import useModal from '../Modals/useModal/useModal';
import { ProfileFormRef } from '../Forms/ProfileForm/ProfileForm.types';
import { useSearchParams } from 'react-router-dom';

export default function UserProfile() {
  const [isEditing, setIsEditing] = useState(false);
  const [isResetProfileForm, setIsResetProfileForm] = useState(false);
  const [isProfileUpdateSuccessToastOpen, setIsProfileUpdateSuccessToastOpen] =
    useState(false);
  const [isProfileFormHasUnsavedChanges, setIsProfileFormHasUnsavedChanges] =
    useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const { closeElement, isProfileDrawerOpen, openProfileDrawer } = useHeader();

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tabletL'));

  const ToastPortal = isTablet
    ? document.getElementById('user-profile-wrapper')
    : document.getElementById('user-header.profile-drawer');

  const intl = useIntl();

  const { openInfoModal, closeModal, isInfoModalOpen } = useModal();

  const formRef = useRef<ProfileFormRef | null>(null);

  const {
    isLoading: isLoadingUserProfile,
    isSuccess: isLoadingUserProfileSuccess,
    isError: isLoadingUserProfileError,
    data: userProfileResponse,
  } = useGetUserProfile();

  const {
    mutate: submitUpdateProfile,
    isPending: isUpdatingProfile,
    isSuccess: isUpdatingProfileSuccess,
    error: updatingProfileError,
  } = useUpdateProfile();

  const handleProfileFormSubmit: SubmitHandler<
    FormType<IProfileFormSchema>
  > = profileData => {
    if (profileData.user && profileData.company) {
      submitUpdateProfile({
        user: profileData.user,
        company: profileData.company,
      });
    }
  };

  const cancelProfileEdit = () => {
    if (isProfileFormHasUnsavedChanges) {
      return openInfoModal();
    }
    setIsResetProfileForm(true);
    setIsEditing(false);
  };

  const handleProfileUnsavedChanges = (value: boolean) => {
    setIsProfileFormHasUnsavedChanges(value);
  };

  const handleDiscardProfileChanges = () => {
    setIsResetProfileForm(true);
    setIsProfileFormHasUnsavedChanges(false);
    setTimeout(() => {
      setIsEditing(false);
      closeModal();
    }, 100);
  };

  const invokeSave = () => {
    if (formRef.current) {
      formRef.current.invokeSubmit();
      closeModal();
    }
  };

  const closeProfile = () => {
    if (isProfileFormHasUnsavedChanges) {
      return openInfoModal();
    }
    setIsEditing(false);
    closeElement();
    if (searchParams.has('profile')) {
      searchParams.delete('profile');
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (isUpdatingProfileSuccess) {
      setIsProfileFormHasUnsavedChanges(false);
      setIsEditing(false);
      setIsProfileUpdateSuccessToastOpen(true);
    }
  }, [isUpdatingProfileSuccess]);

  useEffect(() => {
    if (searchParams.get('profile') === 'true') {
      openProfileDrawer();
    }
  }, [searchParams]);

  return (
    <DrawerWithHeader
      headerLine={intl.formatMessage({
        id: `customer-platform.header.profile-drawer.heading`,
      })}
      id="user-header.profile-drawer"
      onClose={closeProfile}
      open={isProfileDrawerOpen}
    >
      <HeaderDrawerWrapper>
        {isLoadingUserProfile && (
          <LoaderWrapper>
            <CircularProgress size={160} />
          </LoaderWrapper>
        )}

        {isLoadingUserProfileSuccess && userProfileResponse.data && (
          <UserProfileWrapper id="user-profile-wrapper">
            {!isEditing && (
              <>
                <ProfileCompanyDetails {...userProfileResponse.data} />
                <EditButtonWrapper>
                  <Button
                    buttonType="primary"
                    id="user-header.button.go-to-buy-metal"
                    size="large"
                    fullWidth
                    onClick={() => setIsEditing(true)}
                  >
                    <Translate id="customer-platform.shared.user-profile.edit-information-cta" />
                  </Button>
                </EditButtonWrapper>
              </>
            )}
            {isEditing && (
              <ProfileForm
                userProfile={userProfileResponse.data}
                onSubmit={handleProfileFormSubmit}
                onCancel={cancelProfileEdit}
                isPending={isUpdatingProfile}
                isSuccess={isUpdatingProfileSuccess}
                isReset={isResetProfileForm}
                errors={updatingProfileError?.response?.data}
                hasUnsavedChanges={handleProfileUnsavedChanges}
                ref={formRef}
              />
            )}
          </UserProfileWrapper>
        )}

        {isLoadingUserProfileError && (
          <UserProfileWrapper>
            <ToastAlert
              severity="error"
              title={
                <Translate id="customer-platform.shared.user-profile.get-profile.error.title" />
              }
              message={
                <Translate id="customer-platform.shared.user-profile.get-profile.error.message" />
              }
              icon={<RiAlertLine size={24} />}
            ></ToastAlert>
          </UserProfileWrapper>
        )}

        {isInfoModalOpen && (
          <InfoModal
            title={intl.formatMessage({
              id: 'customer-platform.shared.user-profile.modal.title',
            })}
            text={intl.formatMessage({
              id: 'customer-platform.shared.user-profile.modal.text',
            })}
            primaryCta={{
              text: intl.formatMessage({
                id: 'customer-platform.shared.user-profile.modal.cta.primary',
              }),
              onClick: invokeSave,
            }}
            secondaryCta={{
              text: intl.formatMessage({
                id: 'customer-platform.shared.user-profile.modal.cta.secondary',
              }),
              onClick: handleDiscardProfileChanges,
            }}
            onClose={closeModal}
          />
        )}

        {ToastPortal && (
          <>
            {createPortal(
              <SuccessToast
                isToastOpen={isProfileUpdateSuccessToastOpen}
                closeToast={() => setIsProfileUpdateSuccessToastOpen(false)}
                titleId="customer-platform.shared.user-profile.edit-profile.form.success-toast.title"
                messageId="customer-platform.shared.user-profile.edit-profile.form.success-toast.message"
              />,
              ToastPortal,
            )}
          </>
        )}
      </HeaderDrawerWrapper>
    </DrawerWithHeader>
  );
}
